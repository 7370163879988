import {
  Asset,
  IdentityGroupType,
  InputTypeMnemonic,
  StatusMnemonic,
  SubmitType,
} from 'enums';
import PropTypes from 'prop-types';
import React from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../buttons';
import { validateForm } from 'common/forms';
import { useSchedule } from 'hooks';
import {
  clamp,
  getDateRangeLabelFromWeek,
} from 'common';

import {
  ShortText,
  LongText,
  SingleSelect,
  ToggleButton,
  FileUpload,
  Rating,
  Dropdown,
  DatePicker,
  Number,
  MultiSelect,
  SchedulerViewer,
  DurationPicker,
  RatingLongtext,
  EvalRating,
  AbsenceLength,
} from './Inputs';

import {
  HFillContainerV,
  VContainerV,
  VFillContainer,
  VFillContainerVH,
} from '../containers';

import {
  Divider,
  LinearProgress,
  styled,
} from '@mui/material';

import { useFormSubmit } from 'hooks/useFormSubmit';
import { Feedback } from '../Feedback';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../Modal';
import { Spinner } from '../Spinner';
import { InfoText } from './InfoText';
import { DocumentViewer } from './DocumentViewer';
import {
  useFormFactor,
  useStorage,
  useTaskUpsert,
} from 'hooks';
import { Geolocation } from './Inputs/Geolocation';
import {
  buildTaskUpsertVariables,
  debounce,
  formatDate,
} from 'common';
import {
  BodyMedium,
  LabelMedium,
} from '../typography';
import { SchedulerInput } from './Inputs/Scheduler/SchedulerInput';

const SpaceContainer = styled(VContainerV)`
  padding: 1rem 0;

  @media (min-width: 600px) {
    padding: 1rem 0;
  }
`;

const SaveButton = styled(SecondaryButton)`
  margin-right: 1rem;
`;

const DeclineButton = styled(PrimaryButton)`
  margin-right: 1rem;
`;

const StyledSubmitContainer = styled(HFillContainerV)`
  margin: 0;
  padding: 0;
`;

const StyledWrapper = styled(VFillContainer)(
  ({ ismobile }) => ({
    justifyContent: ismobile === 'true' && 'space-between' || 'none',
    padding: 0,
    alignItems: 'flex-start',
  }),
);

const ModalContainer = styled(VFillContainerVH)`
  align-items: flex-start;
`;

const ModalButtonContainer = styled(HFillContainerV)`
  padding: 0;
`;

const SectionTitle = styled(LabelMedium)(({ progress }) => `
  margin-top: 0.5rem;
  position: absolute;
  left: ${progress * 0.45}%;
  bottom: -6px;
  transform: translateX(-50%),
`);

const SaveText = styled(BodyMedium)`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.8rem;
`;

const StyledAssessmentName = styled(BodyMedium)`
  margin-bottom: 1rem;
`;

const StyledPreviousButton = styled(SecondaryButton)`
  margin-right: 0.5rem;
`;

const StyledDivider = styled(Divider)`
  margin: 0.5rem 0 1rem;
`;

/**
 * All the available rating modal types.
 * @type {Object}
 */
const RatingModalTypes = {
  CoachRating: 'CoachRating',
  MentorRating: 'MentorRating',
  SelfRating: 'SelfRating',
};

/**
 * Minimum length of the decline or rating comment.
 * @type {number}
 */
const CommentMinLength = 30;

/**
 * Maximum length of the decline or rating comment.
 * @type {number}
 */
const CommentMaxLength = 1000;

/**
 * Minimum rating score.
 * @type {number}
 */
const MinRating = 1;

/**
 * Maximum rating score.
 * @type {number}
 */
const MaxRating = 5;

/**
 * Calculates the average rating for a given set of questions. This will only take rating type
 * questions into account, will clamp the rating to the min and max, and takes other outlying cases
 * into account.
 * @param {Object[]} questions The array of questions to extract the average rating from.
 * @param {Object} answers An object containing the current set of answers.
 * @return {number} The average rating based on the given questions.
 */
const calculateAverageRating = ({
  questions,
  answers,
}) => {

  if (!Array.isArray(questions)) {
    return MinRating;
  }

  const averageScore = questions.reduce((accumulator, question) => {
    try {
      const answer = JSON.parse(answers[question.id]);

      if (
        question.inputType === InputTypeMnemonic.Rating &&
        answer != null &&
        answer !== '' &&
        !isNaN(answer)
      ) {
        accumulator.totalScore += parseInt(answer);
        accumulator.numberOfQuestions += 1;
      }

      if (
        (
          question.inputType === InputTypeMnemonic.RatingLongtext ||
          question.inputType === InputTypeMnemonic.MentorEvalRating ||
          question.inputType === InputTypeMnemonic.InternEvalRating
        ) &&
        answer?.rating != null &&
        answer?.rating !== '' &&
        !isNaN(answer?.rating)
      ) {
        accumulator.totalScore += parseInt(answer?.rating);
        accumulator.numberOfQuestions += 1;
      }
    } catch {
      // Safe to ignore JSON parse errors.
    }

    return accumulator;
  }, {
    totalScore: 0,
    numberOfQuestions: 0,
  });

  if (averageScore.numberOfQuestions === 0) {
    return MinRating;
  }

  // Round the average score to the closest integer, clamp it between the min and max rating,
  // and set that to be the rating.
  return clamp(Math.round(averageScore.totalScore / averageScore.numberOfQuestions), MinRating, MaxRating);
};

/**
 * Converts a rating from the rating component to a percentage that can be submitted to the backend.
 * @param {number} rating
 * @returns {number}
 */
const calculateRatingPercent = (rating) => 100 / MaxRating * clamp(rating, MinRating, MaxRating);

export const FormController = props => {

  const {
    formDetails,
    readOnly,
    showReviseButton,
    userType,
    userId,
    taskInteractionList,
    upsertCommentLoading,
    handleCommentUpload,
    handleReviseTask,
    childTask,
    internTask,
    scheduleQuestionId,
  } = props;

  const [formFields, setFormFields] = React.useState({ ...formDetails.defaultFormState });
  const [validation, setValidation] = React.useState({ ...formDetails.defaultValidationState });
  const [weekDate, setWeekDate] = React.useState();
  const [touchedFields, setTouchedFields] = React.useState({});

  const [showDeclineModal, setShowDeclineModal] = React.useState(false);
  const [declineComment, setDeclineComment] = React.useState('');

  const [visibleRatingModal, setVisibleRatingModal] = React.useState(null);
  const [rating, setRating] = React.useState(MinRating);
  const [ratingComment, setRatingComment] = React.useState('');
  const [ratingCommentTouched, setRatingCommentTouched] = React.useState(false);

  const [isolatedField, setIsolatedField] = React.useState();
  const [taskInteractionsByQuestion, setTaskInteractionsByQuestion] = React.useState({});

  const [currentScreen, setCurrentScreen] = React.useState(0);
  const [submitType, setSubmitType] = React.useState(SubmitType.Submit);

  const [
    submitForm, {
      data,
      loading,
      error,
    },
  ] = useFormSubmit();
  const [
    upsertTask, {
      data: upsertData,
      loading: upsertLoading,
    },
  ] = useTaskUpsert();

  const {
    schedule,
    loading: scheduleLoading,
  } = useSchedule(formFields?.[scheduleQuestionId]);

  React.useEffect(() => {
    if (!scheduleLoading && schedule) {
      setWeekDate(getDateRangeLabelFromWeek(schedule.week));
    }
  }, [
    schedule,
    scheduleLoading,
  ]);

  const {
    setItem,
    removeItem,
  } = useStorage();

  const navigate = useNavigate();

  const { isMobile } = useFormFactor();

  const groupedQuestions = React.useMemo(() => {
    return formDetails.questions.reduce((groups, question) => {
      const groupKey = question.sectionTitle || 'default';
      groups[groupKey] = groups[groupKey] || [];
      groups[groupKey].push(question);
      return groups;
    }, {});
  }, [formDetails.questions]);

  const isSectioned = React.useMemo(() => {
    return formDetails.questions.some(question => question.sectionTitle);
  }, [formDetails.questions]);

  const sectionTitles = Object.keys(groupedQuestions);
  const [groupValid, setGroupValid] = React.useState(false);
  const [currentGroup, setCurrentGroup] = React.useState(isSectioned
    ? sectionTitles[0]
    : 'default');

  const totalGroups = Math.max(
    ...formDetails.questions.map((question) => question.group),
  );

  const checkGrpQuestionsValid = React.useCallback(() => {
    const currentGroupQuestions = groupedQuestions[currentGroup];
    const answeredQuestions = currentGroupQuestions.filter(question => formFields[question.id] !== undefined && formFields[question.id] !== null);
    const newValidation = validateForm(formFields, currentGroupQuestions);
    setGroupValid(answeredQuestions.length === currentGroupQuestions.length && newValidation.isFormValid);
  }, [
    groupedQuestions,
    formFields,
    currentGroup,
  ]);

  const moveToPreviousGroup = () => {
    const currentIndex = sectionTitles.indexOf(currentGroup);
    if (currentIndex > 0) {
      setCurrentGroup(sectionTitles[currentIndex - 1]);
    }
  };

  const moveToNextGroup = () => {
    const currentIndex = sectionTitles.indexOf(currentGroup);
    if (currentIndex < sectionTitles.length - 1) {
      setCurrentGroup(sectionTitles[currentIndex + 1]);
    }
  };

  React.useEffect(() => {
    const resultMap = {};
    if (taskInteractionList != null && taskInteractionList.length > 0) {
      taskInteractionList.forEach(t => {
        if (t.questionId) {
          if (resultMap[t.questionId]) {
            resultMap[t.questionId].push(t);
          } else {
            resultMap[t.questionId] = [t];
          }
        }
      });
    }

    setTaskInteractionsByQuestion(resultMap);
  }, [taskInteractionList]);

  React.useEffect(() => {

    checkGrpQuestionsValid();

    const newValidation = validateForm(formFields, formDetails.questions);
    setValidation(newValidation);
  }, [
    formFields,
    formDetails.questions,
    checkGrpQuestionsValid,
  ]);

  const removeItemCallback = React.useCallback((key) => {
    removeItem(key);
  }, [removeItem]);

  React.useEffect(() => {
    if (!loading && data && currentScreen !== 1) {
      setCurrentScreen(1);
      removeItemCallback({ key: formDetails.id });
    }
    if (!upsertLoading && upsertData && currentScreen !== 1) {
      setCurrentScreen(1);
      setShowDeclineModal(false);
    }
    if (error && currentScreen !== 2) {
      setCurrentScreen(2);
    }
  }, [
    data,
    loading,
    currentScreen,
    error,
    submitType,
    upsertData,
    upsertLoading,
    formDetails.id,
    removeItemCallback,
  ]);

  const renderRatingModal = () => {

    let ratingDescription = '';
    let commentDescription = '';

    switch (visibleRatingModal) {
      case RatingModalTypes.CoachRating:
        ratingDescription = 'Please rate the work of the student.';
        commentDescription = 'Please provide a comment on why you are giving the above rating.' +
          '<br/><br/>' +
          'Your insights are crucial for guiding the student\'s improvement and understanding.';
        break;

      case RatingModalTypes.SelfRating:
        ratingDescription = 'How would you rate the overall task? The default is the average of ' +
          'your ratings given in the task.';
        commentDescription = 'Please provide a comment on why you are giving the above rating.';
        break;

      case RatingModalTypes.MentorRating:
      default:
        ratingDescription = 'Please rate the overall work.';
        commentDescription = 'Please provide a comment on why you are giving the above rating.';
        break;
    }

    const commentError = ratingComment?.length < CommentMinLength ||
      ratingComment?.length > CommentMaxLength;

    const helperText = ratingCommentTouched && commentError
      ? `Your comment must be between ${CommentMinLength}-${CommentMaxLength} characters long.`
      : '';

    const disableSubmission = commentError ||
      ratingComment == null ||
      ratingComment.trim() === '' ||
      rating == null ||
      isNaN(rating);

    const onSubmitRating = () => {

      switch (visibleRatingModal) {
        case RatingModalTypes.CoachRating:
        case RatingModalTypes.MentorRating:
          submitApproval({
            id: formDetails.id,
            ratingPersonId: userId,
            rating,
            ratingComment: ratingComment,
          });
          break;

        case RatingModalTypes.SelfRating:
          handleSubmitSelfRating({
            ratingPersonId: userId,
            rating,
            ratingComment: ratingComment,
          });
          break;
      }
    };

    const onCancelRating = () => {
      setVisibleRatingModal(null);
      setRating(MinRating);
      setRatingComment('');
      setRatingCommentTouched(false);
    };

    return (
      <ModalContainer>
        <Rating
          title={'Rating'}
          description={ratingDescription}
          value={rating}
          onChange={rating => setRating(rating)}
          min={MinRating}
          max={MaxRating}/>

        <LongText
          title={'Comment'}
          description={commentDescription}
          label={'Comment'}
          error={ratingCommentTouched && commentError}
          helperText={helperText}
          value={ratingComment}
          onChange={(e) => setRatingComment(e.target.value)}
          onBlur={() => setRatingCommentTouched(true)}
        />

        <ModalButtonContainer>
          <SaveButton
            text="Cancel"
            onClick={onCancelRating}
          />

          <PrimaryButton
            text="Submit Rating"
            onClick={onSubmitRating}
            disabled={disableSubmission}
          />
        </ModalButtonContainer>
      </ModalContainer>
    );
  };

  const handleFormSubmit = async (type) => {

    setSubmitType(type);

    if (type === SubmitType.Submit && formDetails?.requiresSelfRating) {

      setRating(calculateAverageRating({
        questions: formDetails.questions,
        answers: formFields,
      }));
      setVisibleRatingModal(RatingModalTypes.SelfRating);

      return;
    }

    submitForm(formDetails, formFields, validation, type, childTask, weekDate);
  };

  const handleSubmitSelfRating = ({
    ratingPersonId,
    rating,
    ratingComment,
  }) => {

    setVisibleRatingModal(null);

    submitForm(
      {
        ...formDetails,
        ratingPersonId,
        ratingPercent: calculateRatingPercent(rating),
        ratingComment,
      },
      formFields,
      validation,
      submitType,
      childTask,
      weekDate,
    );
  };

  const handleDeclineClick = async () => {

    await handleCommentUpload(declineComment);

    upsertTask(buildTaskUpsertVariables(
      [
        {
          id: formDetails.id,
          status: StatusMnemonic.Rejected,
        },
      ],
    ));
  };

  const handleApproveClick = () => {

    if (
      formDetails?.requiresCoachRating &&
      (userType === IdentityGroupType.Coach || userType === IdentityGroupType.Mentor)
    ) {

      setVisibleRatingModal(RatingModalTypes.CoachRating);
      return;
    }

    if (
      formDetails?.requiresMentorRating &&
      (userType === IdentityGroupType.Coach || userType === IdentityGroupType.Mentor)
    ) {

      setVisibleRatingModal(RatingModalTypes.MentorRating);
      return;
    }

    submitApproval({
      id: formDetails.id,
    });
  };

  /**
   * Submits the approval of a task. This can optionally contain a rating.
   * @param {string} id The ID of the form
   * @param {string|null} [ratingPersonId] The person who submitted the rating.
   * @param {string|null} [rating] The rating out of 5.
   * @param {string|null} [ratingComment] The rating comment.
   */
  const submitApproval = ({
    id,
    ratingPersonId = null,
    rating = null,
    ratingComment = null,
  }) => {

    setVisibleRatingModal(null);

    upsertTask(buildTaskUpsertVariables(
      [
        {
          id,
          status: StatusMnemonic.Approved,
          completedTimestamp: new Date().toISOString(),
          ratingPersonId,
          ratingPercent: calculateRatingPercent(rating),
          ratingComment,
        },
      ],
    ));
  };

  const handleFieldChange = (question, value) => {
    setFormFields((prevState) => {
      const newFields = {
        ...prevState,
        [question.id]: value,
      };

      updateLocalSave(newFields);

      return newFields;
    });
  };

  const handleFieldBlur = (questionId) => {
    setTouchedFields(prevState => ({
      ...prevState,
      [questionId]: true,
    }));
  };

  const updateLocalSave = (fields) => {
    const { debounced } = debounce(() => {
      setItem({
        key: formDetails.id,
        value: JSON.stringify(fields),
      });
    }, 2000);
    debounced();
  };

  const renderQuestions = () => {

    return groupedQuestions[currentGroup]
      .map(question => {

        if (validation[question.id]?.isHidden ||
          (internTask && internTask.assessmentMnemonic && internTask.calculatedFields.effectiveStatus !== StatusMnemonic.Completed)) {
          return;
        }

        if (readOnly) {

          if (formFields[question.id] == null) {
            return;
          }

          if (question.default && !question.answer) {
            return;
          }

          if (question.inputType === InputTypeMnemonic.FileUpload) {
            return (
              <DocumentViewer
                key={question.id}
                title={question.viewTitle || question.title}
                groupName={question.groupName}
                fileId={formFields[question.id]}
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.Schedular) {
            return (
              <SchedulerViewer
                key={question.id}
                value={formFields[question.id]}
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.Geolocation) {
            return (
              <Geolocation
                key={question.id}
                title={question.viewTitle || question.title}
                groupName={question.groupName}
                value={formFields[question.id]}
                readOnly={readOnly}
                questionId={question.id}
                questionComments={taskInteractionsByQuestion[question.id]}
                upsertCommentLoading={upsertCommentLoading}
                handleComment={handleCommentUpload}
                userId={userId}
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.MultiSelect) {
            return (
              <InfoText
                key={question.id}
                title={question.viewTitle || question.title}
                description={JSON.parse(formFields[question.id]).join(', ')}
                groupName={question.groupName}
                questionId={question.id}
                questionComments={taskInteractionsByQuestion[question.id]}
                upsertCommentLoading={upsertCommentLoading}
                handleComment={handleCommentUpload}
                userId={userId}
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.DurationPicker) {
            return (
              <InfoText
                key={question.id}
                title={question.viewTitle || question.title}
                questionId={question.id}
                questionComments={taskInteractionsByQuestion[question.id]}
                upsertCommentLoading={upsertCommentLoading}
                handleComment={handleCommentUpload}
                userId={userId}
                description={
                  (() => {
                    const durationObj = JSON.parse(formFields[question.id]);
                    const hours = durationObj?.hours && durationObj?.hours > 0
                      ? `${durationObj?.hours} hours`
                      : '';
                    const minutes = durationObj?.minutes && durationObj?.minutes > 0
                      ? `${durationObj?.minutes} minutes`
                      : '';

                    return `${hours} ${minutes}`;
                  })()
                }
                groupName={question.groupName}
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.RatingLongtext) {
            return (
              <InfoText
                key={question.id}
                title={question.viewTitle || question.title}
                questionId={question.id}
                questionComments={taskInteractionsByQuestion[question.id]}
                upsertCommentLoading={upsertCommentLoading}
                handleComment={handleCommentUpload}
                userId={userId}
                description={
                  (() => {
                    const ratingLongtextObj = JSON.parse(formFields[question.id]);
                    const rating = ratingLongtextObj?.rating
                      ? `${ratingLongtextObj?.rating}/5`
                      : '0/5';
                    const response = ratingLongtextObj?.response ? ratingLongtextObj?.response : '';

                    return `${rating}<br/>${response}`;
                  })()
                }
                groupName={question.groupName}
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.MentorEvalRating) {
            return (
              <InfoText
                key={question.id}
                title={question.viewTitle || question.title}
                questionId={question.id}
                questionComments={taskInteractionsByQuestion[question.id]}
                upsertCommentLoading={upsertCommentLoading}
                handleComment={handleCommentUpload}
                userId={userId}
                description={
                  (() => {
                    const ratingEvalObj = JSON.parse(formFields[question.id]);
                    const evalRating = ratingEvalObj?.rating ? `${ratingEvalObj?.rating}/5` : '0/5';
                    const evalResponse = ratingEvalObj?.response ? ratingEvalObj?.response : '';

                    const internRating = question.ghostAnswer && JSON.parse(question.ghostAnswer).rating;
                    const internReponse = question.ghostAnswer && JSON.parse(question.ghostAnswer).response;

                    return `
                          <i>Student</i>
                          <br/>
                          ${internRating}/5
                          <br/>
                          ${internReponse}
                          <br/><br/>
                          <i>Mentor</i>
                          <br/>
                          ${evalRating}
                          <br/>
                          ${evalResponse}
                        `;
                  })()
                }
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.InternEvalRating) {
            return (
              <InfoText
                key={question.id}
                title={question.viewTitle || question.title}
                questionId={question.id}
                questionComments={taskInteractionsByQuestion[question.id]}
                upsertCommentLoading={upsertCommentLoading}
                handleComment={handleCommentUpload}
                userId={userId}
                description={
                  (() => {
                    const ratingEvalObj = JSON.parse(formFields[question.id]);
                    const evalRating = ratingEvalObj?.rating ? `${ratingEvalObj?.rating}/5` : '0/5';
                    const evalResponse = ratingEvalObj?.response ? ratingEvalObj?.response : '';

                    return `${evalRating}<br/>${evalResponse}`;
                  })()
                }
              />
            );
          }

          if (question.inputType === InputTypeMnemonic.DateTime) {
            return (
              <InfoText
                key={question.id}
                title={question.viewTitle || question.title}
                description={formatDate(formFields[question.id])}
                groupName={question.groupName}
                questionId={question.id}
                questionComments={taskInteractionsByQuestion[question.id]}
                upsertCommentLoading={upsertCommentLoading}
                handleComment={handleCommentUpload}
                userId={userId}
              />
            );
          }

          return (
            <InfoText
              key={question.id}
              title={question.viewTitle || question.title}
              description={formFields[question.id]}
              groupName={question.groupName}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.ToggleButton) &&
          question.inputType === InputTypeMnemonic.ToggleButton) {

          return (
            <ToggleButton
              key={question.id}
              value={formFields[question.id] ?? ''}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              title={question.title}
              options={question.options}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.DateTime) && question.inputType === InputTypeMnemonic.DateTime) {

          return (
            <DatePicker
              key={question.id}
              value={formFields[question.id] ?? ''}
              onChange={(event) => {
                handleFieldChange(question, event && new Date(event).toISOString() || '');
              }}
              title={question.title}
              description={question.description}
              groupName={question.groupName}
              options={question.options}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.Schedular) && question.inputType === InputTypeMnemonic.Schedular) {

          return (
            <SchedulerInput
              key={question.id}
              groupName={question.groupName}
              value={formFields[question.id] ?? ''}
              onChange={(event) => {
                handleFieldChange(question, event);
              }}
              setIsolatedField={setIsolatedField}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.Rating) && question.inputType === InputTypeMnemonic.Rating) {

          return (
            <Rating
              key={question.id}
              value={formFields[question.id] ?? ''}
              onChange={rating => handleFieldChange(question, rating)}
              title={question.title}
              description={question.description}
              groupName={question.groupName}
              min={question.min}
              max={question.max}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.ShortText) && question.inputType === InputTypeMnemonic.ShortText) {
          return (
            <ShortText
              key={question.id}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              value={formFields[question.id]}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.AbsLength) && question.inputType === InputTypeMnemonic.AbsLength) {
          return (
            <AbsenceLength
              key={question.id}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              dateOfTask={formDetails.scheduledStartTimestamp}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              value={formFields[question.id]}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.Number) && question.inputType === InputTypeMnemonic.Number) {
          return (
            <Number
              key={question.id}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              value={formFields[question.id]}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.LongText) && question.inputType === InputTypeMnemonic.LongText) {
          return (
            <LongText
              key={question.id}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              value={formFields[question.id]}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.Dropdown) && question.inputType === InputTypeMnemonic.Dropdown) {
          return (
            <Dropdown
              key={question.id}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              mnemonic={question.mnemonic}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              options={question.options}
              value={formFields[question.id]}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.DurationPicker) && question.inputType === InputTypeMnemonic.DurationPicker) {
          return (
            <DurationPicker
              key={question.id}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              value={formFields[question.id]}
              onChange={(value) => {
                handleFieldChange(question, value);
              }}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.RatingLongtext) && question.inputType === InputTypeMnemonic.RatingLongtext) {
          return (
            <RatingLongtext
              key={question.id}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              value={formFields[question.id]}
              min={question.min}
              max={question.max}
              onChange={(value) => {
                handleFieldChange(question, value);
              }}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.MentorEvalRating || isolatedField === InputTypeMnemonic.InternEvalRating) &&
          (question.inputType === InputTypeMnemonic.MentorEvalRating || question.inputType === InputTypeMnemonic.InternEvalRating)) {
          const internReponse = question.ghostAnswer && JSON.parse(question.ghostAnswer).response;
          const internRating = question.ghostAnswer && JSON.parse(question.ghostAnswer).rating;
          return (
            <EvalRating
              key={question.id}
              title={question.title}
              description={question.description}
              label={question.title}
              placeholder={question.placeholder}
              value={formFields[question.id]}
              min={question.min}
              max={question.max}
              assessmentMnemonic={formDetails.assessmentMnemonic}
              onChange={(value) => {
                handleFieldChange(question, value);
              }}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              inputType={question.inputType}
              evalCritTitle={question.evalCritTitle}
              evalCritItems={question.evalCritItems}
              internResponse={internReponse}
              internRating={internRating}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.Geolocation) && question.inputType === InputTypeMnemonic.Geolocation) {
          return (
            <Geolocation
              key={question.id}
              title={question.title}
              description={question.description}
              groupName={question.groupName}
              label={question.placeholder || question.title}
              placeholder={question.placeholder}
              value={formFields[question.id]}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.MultiSelect) && question.inputType === InputTypeMnemonic.MultiSelect) {
          return (
            <MultiSelect
              key={question.id}
              value={formFields[question.id]}
              onChange={(value) => {
                handleFieldChange(question, value);
              }}
              options={question.options}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              error={touchedFields[question.id] && validation[question.id]?.hasError}
              helperText={touchedFields[question.id] && validation[question.id]?.errorText}
              onBlur={() => {
                handleFieldBlur(question.id);
              }}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.SingleSelect) && question.inputType === InputTypeMnemonic.SingleSelect) {
          return (
            <SingleSelect
              key={question.id}
              value={formFields[question.id]}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              options={question.options}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
              questionId={question.id}
              questionComments={taskInteractionsByQuestion[question.id]}
              upsertCommentLoading={upsertCommentLoading}
              handleComment={handleCommentUpload}
              userId={userId}
            />
          );
        }

        if ((isolatedField == null || isolatedField === InputTypeMnemonic.FileUpload) && question.inputType === InputTypeMnemonic.FileUpload) {
          return (
            <FileUpload
              key={question.id}
              value={formFields[question.id]}
              onChange={(event) => {
                handleFieldChange(question, event.target.value);
              }}
              title={question.title + (!question.required && ' (Optional)' || '')}
              description={question.description}
              groupName={question.groupName}
            />
          );
        }
      })
      .filter(Boolean)
      .flatMap((field, index) => [
        <StyledDivider key={`divider-${index}`}/>,
        field,
      ]).slice(1);
  };

  return (

    <React.Fragment>
      {
        <Modal
          open={loading || upsertLoading}>

          <VFillContainerVH>

            <Spinner
              text={'Loading...'}/>

          </VFillContainerVH>
        </Modal>
      }

      {/* Modal to show when declining a task */}
      <Modal
        open={showDeclineModal}>

        <ModalContainer>

          <LongText
            title={'Decline Task Reason'}
            description={`Please provide feedback on why this task is being declined.<br/><br/>
              Your insights are crucial for guiding the student's improvement and understanding.`}
            label={'Comment'}
            value={declineComment}
            onChange={(e) => setDeclineComment(e.target.value)}
            minLength={CommentMinLength}
          />

          <ModalButtonContainer>
            <SaveButton
              text="Cancel"
              onClick={() => {
                setShowDeclineModal(false);
                setDeclineComment('');
              }}
            />
            <PrimaryButton
              text="Add Comment"
              onClick={() => handleDeclineClick()}
              disabled={declineComment == null || declineComment.trim() === ''}
            />
          </ModalButtonContainer>

        </ModalContainer>
      </Modal>

      {/* Modal to show when a rating is required */}
      <Modal
        open={!!visibleRatingModal}>

        {renderRatingModal()}
      </Modal>

      {currentScreen === 0 && formDetails &&
        <StyledWrapper ismobile={isMobile && 'true' || 'false'}>

          <VContainerV>

            {formDetails.assessmentMnemonic && internTask &&
              <StyledAssessmentName>
                Evaluation
                for: <b>{`${internTask.ownerPersonGivenName} ${internTask.ownerPersonSurname}`}</b>
              </StyledAssessmentName>
            }

            {!readOnly && formDetails.assessmentMnemonic && internTask && internTask.calculatedFields.effectiveStatus !== StatusMnemonic.Completed &&
              <StyledAssessmentName>
                Please allow for the student to completed their assessment before continuing with
                yours.
              </StyledAssessmentName>
            }

            {readOnly && !formDetails.questions.some(q => q.answer) &&
              <BodyMedium>No responses recorded.</BodyMedium>
            }

            {!readOnly && isSectioned && !formDetails.assessmentMnemonic &&
              <SpaceContainer sx={'position: relative; margin-bottom: 1rem;'}>
                <LinearProgress
                  variant="determinate"
                  value={((sectionTitles.indexOf(currentGroup) + 1) / totalGroups) * 100}/>
                <SectionTitle
                  color="primary.p20"
                  progress={((sectionTitles.indexOf(currentGroup) + 1) / totalGroups) * 100}>{currentGroup}</SectionTitle>
              </SpaceContainer>
            }

            {renderQuestions()}
          </VContainerV>

          {!readOnly && isSectioned &&
            <SpaceContainer>
              <HFillContainerV>
                {sectionTitles.indexOf(currentGroup) < sectionTitles.length - 1 && (
                  <React.Fragment>
                    {sectionTitles.indexOf(currentGroup) !== 0 &&
                      <StyledPreviousButton
                        text="Previous"
                        onClick={moveToPreviousGroup}
                        disabled={loading}
                      />
                    }
                    <PrimaryButton
                      text="Continue"
                      onClick={moveToNextGroup}
                      disabled={loading || !groupValid}
                    />
                  </React.Fragment>
                )}
              </HFillContainerV>
            </SpaceContainer>
          }

          <SpaceContainer>
            {!readOnly && isolatedField == null && (internTask == null || (internTask && internTask.calculatedFields.effectiveStatus === StatusMnemonic.Completed)) &&
              <VContainerV>
                {sectionTitles.indexOf(currentGroup) === sectionTitles.length - 1 &&
                  <StyledSubmitContainer>
                    {isSectioned && sectionTitles.indexOf(currentGroup) !== 0 &&
                      <StyledPreviousButton
                        text="Previous"
                        onClick={moveToPreviousGroup}
                        disabled={loading}
                      />
                    }
                    <PrimaryButton
                      text="Submit"
                      onClick={() => handleFormSubmit(SubmitType.Submit)}
                      disabled={loading || !validation.isFormValid}
                    />
                  </StyledSubmitContainer>
                }
                <SaveText>Want to continue later?</SaveText>
                <SecondaryButton
                  text="Save For Later"
                  onClick={() => handleFormSubmit(SubmitType.Save)}
                  disabled={loading}/>
              </VContainerV>
            }
            {readOnly && (
              <React.Fragment>
                {userType === IdentityGroupType.Intern && showReviseButton && (
                  <PrimaryButton
                    text="Revise Task"
                    onClick={handleReviseTask}
                  />
                )}

                {
                  formDetails.status === StatusMnemonic.Review &&
                  (userType === IdentityGroupType.Mentor || userType === IdentityGroupType.Coach) &&
                  sectionTitles.indexOf(currentGroup) === sectionTitles.length - 1
                    ? (
                      <React.Fragment>
                        <SpaceContainer>
                          <HFillContainerV>
                            {sectionTitles.indexOf(currentGroup) !== 0 &&
                              <StyledPreviousButton
                                text="Previous"
                                onClick={moveToPreviousGroup}
                                disabled={loading}
                              />
                            }
                          </HFillContainerV>
                        </SpaceContainer>

                        <HFillContainerV>
                          <DeclineButton
                            text="Decline"
                            color="error"
                            onClick={() => setShowDeclineModal(true)}
                            disabled={upsertLoading}
                          />

                          <PrimaryButton
                            text="Approve"
                            onClick={handleApproveClick}
                            disabled={upsertLoading}
                          />
                        </HFillContainerV>
                      </React.Fragment>
                    )
                    : (
                      <HFillContainerV>
                        <React.Fragment>
                          {sectionTitles.indexOf(currentGroup) !== 0 &&
                            <StyledPreviousButton
                              text="Previous"
                              onClick={moveToPreviousGroup}
                              disabled={loading}
                            />
                          }
                          {sectionTitles.indexOf(currentGroup) < sectionTitles.length - 1 &&
                            <PrimaryButton
                              text="Continue"
                              onClick={moveToNextGroup}
                              disabled={loading}
                            />
                          }
                        </React.Fragment>
                      </HFillContainerV>
                    )
                }
              </React.Fragment>
            )}
          </SpaceContainer>
        </StyledWrapper>
      }

      {currentScreen === 1 &&
        <Feedback
          imageUrl={submitType === SubmitType.Submit &&
            Asset.Image.Other.BookStack ||
            Asset.Image.Other.Book
          }
          headerText={
            submitType === SubmitType.Submit &&
            'Task Successful!' ||
            'Task Saved!'
          }
          bodyText={
            submitType === SubmitType.Submit &&
            'Great job completing your task! Your dedication shines bright.' ||
            'Great job making progress with your task! You will be done in no time.'
          }
          infoText={
            submitType === SubmitType.Submit && userType === IdentityGroupType.Intern
              ? 'Your task is now with your mentor for review. We\'ll notify you once there\'s feedback.'
              : submitType === SubmitType.Submit && userType !== IdentityGroupType.Intern
                ? null
                : 'Your progress is saved for you to continue later.'
          }

          buttonText="Go Back"
          buttonAction={() => navigate('/home')}/>
      }

      {currentScreen === 2 &&
        <Feedback
          imageUrl={Asset.Image.Other.BrokenPencil}
          headerText="Something Went Wrong!"
          bodyText="We encountered a hiccup while processing your task. Not to worry, though – we're on it!"
          infoText="Please try submitting your task again. If the issue persists, reach out to us for support"
          type="Error"
          buttonText="Try Again"
          buttonAction={() => handleFormSubmit()}/>
      }

    </React.Fragment>
  );
};

FormController.displayName = 'FormController';

FormController.propTypes = {
  formDetails: PropTypes.any.isRequired,
  readOnly: PropTypes.bool,
  showReviseButton: PropTypes.bool,
  userId: PropTypes.string,
  userType: PropTypes.oneOf(Object.values(IdentityGroupType)),
  taskInteractionList: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleCommentUpload: PropTypes.func,
  handleReviseTask: PropTypes.func,
  childTask: PropTypes.any,
  internTask: PropTypes.any,
  scheduleQuestionId: PropTypes.any,
};

FormController.defaultProps = {
  readOnly: false,
  userId: '',
  userType: IdentityGroupType.Intern,
  upsertCommentLoading: false,
  handleCommentUpload: (comment) => console.log(comment),
  showReviseButton: false,
  handleReviseTask: () => {},
  scheduleQuestionId: null,
};
