import {
  IdentityGroupType,
  Navigate,
  RoutePath,
  TaskTypeMnemonic,
} from 'enums';

import {
  add,
  addWeeks,
  endOfDay,
  endOfWeek,
  format,
  formatDistanceToNow,
  getWeek,
  startOfDay,
  startOfWeek,
  sub,
  subWeeks,
} from 'date-fns';

import { enUS } from 'date-fns/locale';

/**
 * @typedef {Object} DeferredPromise
 * @property {Promise} promise
 * @property {function(*): void} resolve
 * @property {function(Error): void} reject
 */

/**
 * Creates a deferred promise with an optional timeout. This promise can be programmatically
 * resolved or rejected outside the promise constructor.
 * @param {Object} [params]
 * @param {number} [timeout] A timeout after which the promise is automatically rejected.
 * @param {string} [timeoutErrorMessage] The message to use for the error when the timeout has been
 * reached.
 * @return {DeferredPromise}
 */
export const createDeferredPromise = ({
  timeout,
  timeoutErrorMessage = 'Timeout reached for deferred promise',
} = {}) => {

  const deferred = {};

  deferred.promise = new Promise((resolve, reject) => {

    deferred.resolve = resolve;
    deferred.reject = reject;

    if (timeout) {

      setTimeout(
        () => reject(new Error(timeoutErrorMessage)),
        timeout,
      );
    }
  });

  return deferred;
};

export const joinPath = (
  url,
  path,
) => {

  if (path.startsWith('https')) {
    return path;
  }

  return `${url}${path}`.replace(/([^:]\/)\/+/g, '$1');
};

export const deepMerge = (
  base = {},
  other = {},
) => {

  const merged = {
    ...base,
  };

  for (const [key, value] of Object.entries(other ?? {})) {

    if (typeof value === 'object' && !Array.isArray(value)) {

      merged[key] = deepMerge(merged[key], value);

      continue;
    }

    merged[key] = value;
  }

  return merged;
};

/*
 * @typedef {Object} Tokens
 * @property {string} [id] The OIDC ID token. This is contains declarations about the user after he
 * was authenticated.
 * @property {string|undefined} access The access token. Used to access remote resources.
 * @property {string} [refresh] The refresh token that can be used to obtain a new set of tokens.
 * @property {number} [expiresIn] The number of seconds for how long the access tokens are valid
 * for.
 * @property {number|undefined} issuedAt The unix epoch (in seconds) for when the tokens were issued.
 */

export const generateId = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {

  let r = Math.random() * 16 | 0;
  let v = (c === 'x' && r) || ((r & 0x3) | 0x8);

  return v.toString(16);
});

export const clone = value => {

  if (typeof value === 'undefined' || value === null) {
    return null;
  }

  if (typeof value === 'function') {
    return value;
  }

  if (Array.isArray(value)) {

    let cloneValue = [];

    for (const item of value) {

      cloneValue.push(clone(item));
    }

    return cloneValue;
  }

  if (typeof value === 'object') {

    let cloneValue = {};

    for (const [key, val] of Object.entries(value)) {

      cloneValue[key] = clone(val);
    }

    return cloneValue;
  }

  return value;
};

export const parseJwt = token => {

  try {

    let base64Url = token.split('.')[1];

    let base64 = base64Url
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + (
            '00' + c
              .charCodeAt(0)
              .toString(16)
          ).slice(-2),
        )
        .join(''),
    );

    return JSON.parse(jsonPayload);

  } catch {
    return undefined;
  }
};

export const debounce = (
  func,
  delay,
) => {

  let bouncing;

  const debounced = function () {

    const context = this;
    const args = arguments;

    clearTimeout(bouncing);

    bouncing = setTimeout(
      () => func.apply(context, args),
      delay,
    );
  };

  const clear = () => clearTimeout(bouncing);

  return {
    debounced,
    clear,
  };
};

const byteTo8BitBinaryString = n => n.toString(2).padStart(8, 0);

export const bufferToUrlSafeBase64 = array => {

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  const length = array.length;
  const lastIndex = Math.floor((length - 1) / 3);
  let result = '';

  for (let i = 0; i <= lastIndex; i++) {

    const chunk =
      byteTo8BitBinaryString(array[3 * i]) +
      byteTo8BitBinaryString(((3 * i + 1) < length && array[3 * i + 1]) || '0') +
      byteTo8BitBinaryString(((3 * i + 2) < length && array[3 * i + 2]) || '0');

    for (let charIndex = 0; charIndex < 4; charIndex++) {
      const current = chunk.substring(charIndex * 6, charIndex * 6 + 6);
      const currentInt = parseInt(current, 2);

      //Case with 2 equal signs at the end
      if (i === lastIndex && length % 3 === 1 && (charIndex === 2 || charIndex === 3)) {
        result += '=';
        continue;
      }

      //Case with 1 equal sign at the end
      if (i === lastIndex && length % 3 === 2 && charIndex === 3) {
        result += '=';
        continue;
      }

      result += alphabet[currentInt];
    }
  }

  return result;
};

/**
 * The number of seconds that have elapsed since the Unix epoch.
 * @returns {number}
 */
export const unixTime = () => Math.floor(Date.now() / 1000);

/**
 * Checks if the specified tokens are valid and not expired yet.
 * @param {Object} params
 * @param {Tokens} params.tokens The tokens to validate.
 * @param {number} params.buffer The number of seconds to use as buffer while determining if the
 * tokens are expired. For example: a value of 120 will imply that the tokens will be considered to
 * be expired 2 minutes before their actual expiry timestamp.
 * @returns {boolean}
 */
export const areTokensValid = ({
  tokens,
  buffer,
}) => {

  if (!tokens.access || !tokens.issuedAt) {
    return false;
  }

  if (!tokens.expiresIn) {
    return true;
  }

  return unixTime() < tokens.issuedAt + tokens.expiresIn - buffer;
};

export const sha256 = async input => {

  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const buffer = await crypto.subtle.digest('SHA-256', data);

  return btoa(String.fromCharCode(...new Uint8Array(buffer)));
};

export const trimString = (str) => (!!str && typeof str.trim === 'function' && str.trim()) || str;

export const getFullEnvironment = ({
  environmentConfig,
  commonEnvironmentConfig,
}) => {

  const mergedEnvironment = deepMerge(commonEnvironmentConfig, environmentConfig);

  if (mergedEnvironment?.variables?.paths) {

    for (const domain of Object.values(mergedEnvironment.variables.paths)) {

      const {
        base,
        ...paths
      } = domain;

      for (const [pathKey, path] of Object.entries(paths)) {

        domain[pathKey] = joinPath(base, path);
      }
    }
  }

  return mergedEnvironment;
};

/**
 * Utility function to return the previous path in the navigation history stack.
 *
 * @return {string|number} Return the previous path from the stack if it exists, otherwise return
 * the fallback path.
 */
export const getBackNavigationPath = () => {

  /*
    React-router-dom v6 does not offer a direct way to inspect the history stack.
    The idx field on the window.history.state will be `0` if there is nothing else in the history.
  */
  if (window?.history?.state?.idx === 0 || window?.history?.state?.idx == null) {
    return RoutePath.Home;
  }

  return Navigate.Back;
};

export const getQueryParameter = (parameterName) => {

  const queryParameters = new URLSearchParams(window.location.search);
  return queryParameters.get(parameterName);
};

/**
 * Utility function to return the start and end date and time for last week, this week and next
 * week.
 *
 * @return {object} Return the start and end date and time for last week, this week and next week.
 */
export const getWeekRange = () => {
  const currentDate = new Date();

  const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endOfCurrentWeek = endOfWeek(currentDate, { weekStartsOn: 1 });

  const startOfLastWeek = startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });
  const endOfLastWeek = endOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });

  const startOfNextWeek = startOfWeek(addWeeks(currentDate, 1), { weekStartsOn: 1 });
  const endOfNextWeek = endOfWeek(addWeeks(currentDate, 1), { weekStartsOn: 1 });

  return {
    lastWeek: [
      startOfLastWeek.toUTCString(),
      endOfLastWeek.toUTCString(),
    ],
    currentWeek: [
      startOfCurrentWeek.toUTCString(),
      endOfCurrentWeek.toUTCString(),
    ],
    nextWeek: [
      startOfNextWeek.toUTCString(),
      endOfNextWeek.toUTCString(),
    ],
  };
};

export const getDateRangeLabelFromWeek = (week = 0) => {
  const now = new Date();
  const currentWeek = getWeekNormalised(now);

  let targetDate;

  if (week > currentWeek) {
    targetDate = add(now, { weeks: week - currentWeek });
  } else {
    targetDate = sub(now, { weeks: currentWeek - week });
  }

  const start = startOfWeek(targetDate, { weekStartsOn: 1 });
  const end = endOfWeek(targetDate, { weekStartsOn: 1 });

  return `${format(start, 'd MMMM')} - ${format(end, 'd MMMM')}`;
};

export const filterTasksBySelectedFilters = (tasks, filters) => {
  if (filters == null || tasks == null) {
    return tasks;
  }

  return tasks.filter(task => {
    return Object.keys(filters).every((field) => {
      if (Object.values(filters[field]).every(value => !value)) {
        return true;
      }

      if (field === 'status') {
        return filters[field][task[field]] || task.calculatedFields.metaStatuses.some(meta => {
          return filters[field][meta];
        });
      }

      return filters[field][task[field]];
    });
  });
};

export const buildFilterGroupsFromTasks = ({
  tasks,
  showSchoolFilter = false,
}) => {

  if (!Array.isArray(tasks) || tasks.length <= 0) {
    return [];
  }

  const {
    statusSet,
    taskTemplateTitleSet,
    titleMnemonicMap,
    schoolsSet,
  } = tasks.reduce((accumulator, task) => {

    if (Array.isArray(task.calculatedFields.metaStatuses)) {
      task.calculatedFields.metaStatuses.forEach(status => accumulator.statusSet.add(status));
    }

    if (task.status) {
      accumulator.statusSet.add(task.status);
    }

    if (task.taskTemplateTitle) {
      accumulator.taskTemplateTitleSet.add(task.taskTemplateTitle);
    }

    if (task.taskMnemonic && task.taskTemplateTitle) {
      accumulator.titleMnemonicMap.set(task.taskMnemonic, task.taskTemplateTitle);
    }

    if (showSchoolFilter && task.school) {
      accumulator.schoolsSet.add(task.school);
    }

    return accumulator;
  }, {
    statusSet: new Set(),
    taskTemplateTitleSet: new Set(),
    titleMnemonicMap: new Map(),
    schoolsSet: new Set(),
  });

  const filters = [
    {
      field: 'status',
      title: 'Task Status',
      items: Array.from(statusSet),
    },
    {
      field: 'taskTemplateTitle',
      title: 'Task Type',
      items: Array.from(taskTemplateTitleSet),
      default: {
        [IdentityGroupType.Coach]: {
          'taskTemplateTitle': titleMnemonicMap.has(TaskTypeMnemonic.InternLessonPlan)
            ? { [titleMnemonicMap.get(TaskTypeMnemonic.InternLessonPlan)]: true }
            : {},
        },
      },
    },
  ];

  if (showSchoolFilter && schoolsSet.size > 0) {
    filters.push({
      field: 'school',
      title: 'Schools',
      items: Array.from(schoolsSet),
    });
  }

  return filters;
};

/**
 * Utility function to return array with start and end date and time for current day.
 *
 * @returns {array} Return array with start and end date and time for the current day.
 */
export const getCurrentDayRangeFormatted = () => {
  const currentDate = new Date();

  const startOfCurrentDay = startOfDay(currentDate);
  const endOfCurrentDay = endOfDay(currentDate);

  return [
    startOfCurrentDay.toUTCString(),
    endOfCurrentDay.toUTCString(),
  ];
};

/**
 * Utility function to format date and time to display.
 * @param {string} dateString
 * @returns Return Formatted date: d MMMM yyyy | HH:mm
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, 'd MMMM yyyy | hh:mm a', { locale: enUS });
};

/**
 * Utility function to calculate time difference.
 *
 * @returns {string} Returns formatted time difference.
 */
export const calculateTimeAgo = (date) => {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

/**
 * Utility function to get day of the week, a date to sort, and a display date.
 *
 * @returns {{dayOfWeek: string, formattedDate: string, sortableDate: string}} Returns day of the
 * week, a date to sort, and a display date.
 */
export const getDayOfWeekAndDate = (timestamp) => {
  const date = new Date(timestamp);

  const dayOfWeek = format(date, 'EEEE');
  const formattedDate = format(date, 'do MMMM');
  const sortableDate = format(date, 'yyyy-MM-dd');

  return {
    dayOfWeek,
    formattedDate,
    sortableDate,
  };
};

/**
 * Utility function that receives a time stamp as a string and gets the
 * number of days left until the friday of that week.
 *
 * @param {string} timestamp
 * @returns {number} Returns the number of days left until Friday.
 */
export const getDaysLeftInWeek = (timestamp) => {
  const dateObj = new Date(timestamp);
  const dayOfWeek = dateObj.getDay();

  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return 1;
  }

  let daysUntilFriday = 5 - dayOfWeek + 1; // Add 1 to include the current day

  if (daysUntilFriday === 0) {
    daysUntilFriday = 1;
  }

  if (daysUntilFriday < 0) {
    daysUntilFriday += 7;
  }

  return daysUntilFriday;
};

/**
 * Utility function that receives a number and returns an array of counting numbers
 * until that number
 *
 * @param {number} rangeNumber
 * @returns {array} Returns an array of counting numbers until that number.
 */
export const generateCountingArray = (number) => {
  return Array.from(Array(number).keys(), x => x + 1);
};

/** Utility function to capitilize the first letter of a word
 *@param {string} word
 * @returns {string} Return an empty string if the input is falsy else return the word with the
 *   first letter capitalized
 */
export const capitalizeFirstLetter = (word) => {
  if (!word) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

/**
 * Returns a clamped value based on the given arguments.
 * @param {number} num The actual number to clamp.
 * @param {number} min The min value.
 * @param {number} max The max value.
 * @returns {number} A clamped number
 */
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

/**
 * Tests if the specified value is a function or not.
 * @param {*} value
 * @returns {boolean} True of the value is a function and false otherwise.
 */
export const isFunction = (value) => typeof value === 'function';

/**
 * Tests if the specified value is a string or not.
 * @param {*} value
 * @returns {boolean} True of the value is a string and false otherwise.
 */
export const isString = (value) => typeof value === 'string';

/**
 * Tests if the specified value is an array and has a length greater than 0.
 * @param {*} value
 * @returns {boolean} True only if the value is an array and it's length is greater than 0. False
 * otherwise
 */
export const isNonEmptyArray = (value) => Array.isArray(value) && value.length > 0;

export const isEqualObjectArrays = (a, b) => {
  if (a == null || b == null || !Array.isArray(a) || !Array.isArray(b) || a.length < 0 || b.length < 0) {
    return false;
  }

  if (a.length === b.length) {
    for (let i = 0; i < a.length; i++) {
      return Object.keys(a[i]).every(key => {
        if (b[i] == null) {
          return false;
        }

        return a[i][key] === b[i][key];
      });
    }
  }

  return false;
};

export const getWeekNormalised = (date) => {
  const options = {
    firstWeekContainsDate: 1,
    weekStartsOn: 1 
  };
  return getWeek(date, options);
}

export const getFirstMondayNormalised = (year = new Date().getFullYear()) => {
  let fourthJan = new Date(year, 0, 4);
  let dayOfWeek = fourthJan.getDay();

  let daysToSubtract = (dayOfWeek === 0) ? 6 : dayOfWeek - 1;
  let firstMonday = new Date(fourthJan);
  firstMonday.setDate(fourthJan.getDate() - daysToSubtract);

  return firstMonday;
}