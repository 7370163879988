export const ThutoTheme = {
  typography: {
    fontFamily: 'Inter',
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 700,
    },
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 800,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      // Material UI
      main: '#3CAA49',
      light: '#B5DFBA',
      dark: '#0F2A12',
      contrastText: '#fff',
      // full palette
      p10: '#0F2A12',
      p20: '#1E5524',
      p30: '#2D7F36',
      p40: '#3CAA49',
      p50: '#54B45F',
      p60: '#6CBF76',
      p70: '#85C98D',
      p80: '#9DD4A4',
      p90: '#B5DFBA',
      p95: '#CEE9D1',
      p99: '#E6F4E8',
      p100:'#F2F9F3',
    },
    secondary: {
      main: '#5C6E72',
      light: '#C1C8CA',
      dark: '#171B1C',
      contrastText: '#fff',

      s10: '#171B1C',
      s20: '#2E3739',
      s30: '#455255',
      s40: '#5C6E72',
      s50: '#708083',
      s60: '#849295',
      s70: '#99A4A6',
      s80: '#ADB6B8',
      s90: '#C1C8CA',
      s95: '#D6DADB',
      s99: '#EAECED',
      s100: '#F4F5F5',
    },
    tertiary: {
      main: '#AF7B48',
      light: '#F7DCC3',
      dark: '#3A2918',
      contrastText: '#fff',

      t10: '#3A2918',
      t20: '#755230',
      t30: '#AF7B48',
      t40: '#EAA461',
      t50: '#040303',
      t60: '#EFBA88',
      t70: '#F1C69C',
      t80: '#F4D1B0',
      t90: '#F7DCC3',
      t95: '#F9E8D7',
      t99: '#FCF3EB',
    },
    error: {
      main: '#B3261E',
      light: '#F9DEDC',
      dark: '#410E0B',
      contrastText: '#fff',

      e10: '#410E0B',
      e20: '#601410',
      e30: '#8C1D18',
      e40: '#B3261E',
      e50: '#DC362E',
      e60: '#E46962',
      e70: '#EC928E',
      e80: '#F2B8B5',
      e90: '#F9DEDC',
      e95: '#FCEEEE',
      e99: '#FFFBF9',
    },
    neutral: {
      main: '#B3261E',
      light: '#F9DEDC',
      dark: '#410E0B',
      contrastText: '#fff',

      n10: '#111311',
      n20: '#232623',
      n30: '#343935',
      n40: '#464C47',
      n50: '#5F6560',
      n60: '#797F7A',
      n70: '#939894',
      n80: '#ADB2AD',
      n90: '#C7CBC7',
      n95: '#E1E5E1',
      n99: '#FBFFFB',
    },
    neutralVariant: {
      main: '#5D6466',
      light: '#CDD2D3',
      dark: '#171919',
      contrastText: '#fff',

      nv10: '#171919',
      nv20: '#2E3233',
      nv30: '#454B4C',
      nv40: '#5D6466',
      nv50: '#737A7B',
      nv60: '#8A9091',
      nv70: '#A0A6A7',
      nv80: '#B7BCBD',
      nv90: '#CDD2D3',
      nv95: '#E4E8E9',
      nv99: '#FFFBFE',
    },
    standard: {
      black: '#000',
      white: '#fff',
    },
  },
};
