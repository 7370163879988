import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const BodyMedium = props =>

  <Typography
    color="secondary"
    {...props}
    variant={TypographyVariant.Body1}>

    {props.children}
  </Typography>
;

BodyMedium.displayName = 'BodyMedium';
