import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LinearProgress, styled } from '@mui/material';

import { 
  useIdentity,
  useStorage,
} from 'hooks';

import {
  JourneyName,
  ScreenName,
  IdentityGroupType,
  Asset,
  ButtonVariant
} from 'enums';

import { 
  BodyLarge,
  BodySmall, 
  HContainerVH, 
  VContainerTextCenterVH, 
  HeadlineMedium, 
  PrimaryButton, 
  Scene, 
  TextButton, 
  VFillContainer,
  SpinnerButton,
  Feedback
} from 'ui/common';

const HeaderContainer = styled(HContainerVH)`
  height: 4rem;
  postion: static;
`;

const SpaceAround = styled(VFillContainer)`
  justify-content: space-around;
`;

const StyledImg = styled('img')`
  margin-bottom: 3rem;
  border: 0.25rem solid rgba(116, 119, 117, 0.5);
  border-radius: 1.125rem;
`;

const StyledButton = styled(PrimaryButton)`
@media (min-width: 600px) {
  max-width: 228px;
  }
`;

const StyledSpinnerButton = styled(SpinnerButton)`
@media (min-width: 600px) {
  max-width: 228px;
  }
`;

export const Onboarding = () => {

  const navigate = useNavigate();
  const { setItem } = useStorage();

  const { user } = useIdentity(Onboarding.context);
  const userType = user.group || IdentityGroupType.Intern; 

  const [step, setStep] = useState(1);
  const totalSteps = 5;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleNext = () => {
    if (step < totalSteps) {
      setStep(step + 1);
    }
  };

  const handleSkip = () => {
    if(step === totalSteps){
      setItem({key:'onboarded',value:'true', userSpecific:true});
      navigate('/home');
    }
    setStep(totalSteps);
  };

  const handlePermissions = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      () => {
        console.log('Geolocation permission granted');
        setItem({key:'onboarded',value:'true', userSpecific:true});
        navigate('/home');
      },
      (error) => {
        console.error('Error getting user location:', error.message);
        if (error.message.includes('denied Geolocation'))
        {
          setItem({key:'onboarded',value:'true', userSpecific:true});
          navigate('/home');
        } else {
          setError(true);
          setLoading(false);
        }
      }
    );
  };

  const handleFinish = () => {
    setItem({key:'onboarded',value:'true', userSpecific:true});
    navigate('/home');
  };

  const handleError = () => {
    setError(false);
    handlePermissions();
  }

  return (

    <Scene
      headerVisible={false}
      footerVisible={false}
    >

      {!error ?

        <SpaceAround>

          <HeaderContainer>
            <BodyLarge>Onboarding</BodyLarge>
          </HeaderContainer>

          <LinearProgress variant="determinate" value={(step / totalSteps) * 100}  />

            {step === 1 && (
              <React.Fragment>
                <HContainerVH>
                  <HeadlineMedium color='secondary.s40'>YOUR DASHBOARD</HeadlineMedium>
                </HContainerVH>
                <VContainerTextCenterVH>
                  <StyledImg src={Asset.Image.Onboarding.Dashboard}/>
                  <BodySmall>
                    {userType === IdentityGroupType.Intern ? 
                      'Welcome to your Dashboard! This is where you\'ll check in daily, monitor your progress, and manage tasks.' 
                    : 'This is where you can monitor student progress, review submitted tasks, and provide valuable feedback. Think of it as your command centre for guiding and supporting your students.'
                    }
                    
                  </BodySmall>
                </VContainerTextCenterVH>
              </React.Fragment>
            )}
            {step === 2 && (
              <React.Fragment>
              <HContainerVH>
                <HeadlineMedium color='secondary.s40'>
                  {userType === IdentityGroupType.Intern ? 'MANAGING TASK' : 'STUDENT TASKS' }
                </HeadlineMedium>
              </HContainerVH>
              <VContainerTextCenterVH>
                <StyledImg src={Asset.Image.Onboarding.AddTask}/>
                <BodySmall>
                  {userType === IdentityGroupType.Intern ? 
                    'While your Dashboard offers an overview, the Add Task screen is where you\'ll create new tasks. Remember, creating a task is the first step before you can mark it complete.' 
                  : 'As a mentor, you play a crucial role in overseeing student tasks. You can approve or request revisions on tasks and lesson plans, offering feedback and valuable guidance to shape your students\' learning journey.'
                  }
                </BodySmall>
              </VContainerTextCenterVH>
            </React.Fragment>
            )}
            {step === 3 && (
              <React.Fragment>
              <HContainerVH>
                <HeadlineMedium color='secondary.s40'>
                  {userType === IdentityGroupType.Intern ? 'TASK AND FEEDBACK' : 'YOUR TASKS' }
                </HeadlineMedium>
              </HContainerVH>
              <VContainerTextCenterVH>
                <StyledImg src={Asset.Image.Onboarding.Engagement}/>
                <BodySmall>
                  {userType === IdentityGroupType.Intern ? 
                    'Your tasks, once submitted, go to your mentor for approval. Stay on top of mentor feedback through notifications to enhance your learning experience.' 
                  : 'Your role extends beyond approving tasks. Engage with students through a variety of activities including lesson observations, scheduling one-on-ones, conducting annual evaluations, and more.'
                  }
                </BodySmall>
              </VContainerTextCenterVH>
            </React.Fragment>
            )}
            {step === 4 && (
              <React.Fragment>
                <HContainerVH>
                  <HeadlineMedium color='secondary.s40'>
                    {userType === IdentityGroupType.Intern ? 'GETTING HELP' : 'MENTOR SUPPORT' }
                  </HeadlineMedium>
                </HContainerVH>
                <VContainerTextCenterVH>
                  <StyledImg src={Asset.Image.Onboarding.HelpAndSupport}/>
                  <BodySmall>
                    {userType === IdentityGroupType.Intern ? 
                      'Need assistance or looking for resources? Our Support section is here for you. From FAQs to detailed guides, find answers and get help for any challenges.' 
                    : 'Whether you\'re looking for answers to common questions or need guidance on mentorship practices, our resources are here to assist you.'
                    }
                  </BodySmall>
                </VContainerTextCenterVH>
              </React.Fragment>
            )}
            {step === 5 && (
              <React.Fragment>
              <HContainerVH>
                <HeadlineMedium color='secondary.s40'>
                  {userType === IdentityGroupType.Intern ? 'FINAL STEP' : 'READY TO GO?' }
                </HeadlineMedium>
              </HContainerVH>
              <VContainerTextCenterVH>
                <StyledImg src={Asset.Image.Onboarding.CheckInDemo}/>
                <BodySmall>
                  {userType === IdentityGroupType.Intern ? 
                    'We need location permissions to verify your Check In attendance accurately. Your privacy is paramount; this data is solely for attendance purposes.' 
                  : 'You\'re all set to make a significant impact on your students\' development. With the tools and knowledge at your disposal, you\'re ready to guide them towards success.'
                  }
                </BodySmall>
              </VContainerTextCenterVH>
            </React.Fragment>
            )}

          <HContainerVH>
            <TextButton text='Skip' onClick={handleSkip}/>
            {step === totalSteps && userType === IdentityGroupType.Intern ? (
              <StyledSpinnerButton 
                variant={ButtonVariant.Contained}
                loading={loading}
                text='Allow Permissions'
                onClick={handlePermissions}
              />
            ) : step === totalSteps && userType !== IdentityGroupType.Intern ? (
              <StyledButton text='Start Mentoring' onClick={handleFinish} />
            ) : (
              <StyledButton text='Next' onClick={handleNext} />
            )}
          </HContainerVH>

        </SpaceAround>
      : 
        <Feedback
          imageUrl={Asset.Image.Other.BrokenPencil}
          headerText='Something Went Wrong!'
          bodyText="We encountered a hiccup trying to get your location."
          infoText="Please try again. If the issue persists, reach out to us for support."
          type="Error"
          buttonText='Try Again'
          buttonAction={() => handleError()}
        />
      }
    </Scene>
    
  );
};
  
  
Onboarding.displayName = 'Onboarding';

Onboarding.context = {
  screen: ScreenName.Onboarding,
  journey: JourneyName.Onboarding,
  component: Onboarding.displayName,
};
  