import {
  IdentityGroupType,
  TeachingPhaseMnemonic,
  Asset,
} from 'enums';

import {
  usePersonTypes,
  usePersons,
  useSchools,
  usePersonCognitoUpsert,
  usePersonSchoolUpsert,
  usePersonByIdUpsert,
} from 'hooks';

import { 
  buildPersonUpsertVariables,
  buildPersonSchoolUpsertVariables,
} from 'common/person';

import
  PropTypes
from 'prop-types';

import {
  styled,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';


import {
  HFillContainerV,
  HeadlineLarge,
  PrimaryButton,
  ShortText,
  BodyMedium,
  Dropdown,
  Feedback,
  TitleMedium,
  Spinner,
  VContainerVH,
  InfoText,
} from 'ui/common';

import {   
  isValidEmail,
} from 'common';

import {
  useNavigate,
} from 'react-router-dom';

import React, { useState , useEffect } from 'react';

const StyledHeadline = styled(HeadlineLarge)`
  margin-top: 0.5rem;
`;

const StyledBody = styled(BodyMedium)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const StyledFormControl = styled(FormControl)`
  padding: 0 0 16px 0;
  color: primary;
`;


const StyledErrorBody = styled(BodyMedium)(({ theme }) => `
  background-color: ${theme.palette.error.light};
  padding: 1em;
  margin-bottom: 1em;
`,
);

const StyledFormControlDivider = styled(StyledFormControl)(({ theme }) => `
  background-color: ${theme.palette.secondary.s100};
  padding: 1em;
  margin-bottom: 1em;
`,
);

const userFormModes = {
  create : {
    success : {
      headerText: 'User Created', 
      bodyText: 'Great job creating a new user!',
      infoText: 'The new user has been sent an invitation email.'
    },
    headers : {
      main: 'New User Onboarding',
      secondary: 'Here\'s where you can add new users to the system'
    }
    
  },
  edit: {
    success: {
      headerText: 'User Updated',
      bodyText:'Great job updating the user details!',
      infoText:"The user has been successfully updated."
    },
    headers : {
      main: 'Edit Existing Student',
      secondary: ''
    }
  },
}


export const User = props => {
  const {mode, selectedUser} = props;

  const navigate = useNavigate();

  const [newPersonType, setPersonType] = useState('');
  const [personDetails, setPersonDetails] = useState({});
  const [isFormValid, setIsFormValid] = React.useState(false);

  const [upsertPersonCognito, {loading: upsertPersonCognitoLoading, data: upsertPersonCognitoData, error: upsertPersonCognitoError}] = usePersonCognitoUpsert();
  const [upsertPersonById, {loading: upsertPersonLoading, data: upsertPersonData}] = usePersonByIdUpsert();
  const [upsertPersonSchool, {loading: upsertPersonSchoolLoading, data: upsertPersonSchoolData}] = usePersonSchoolUpsert();

  useEffect(() => {
      setPersonDetails({});
      setPersonType('');
  }, [mode]);

  useEffect(() => {
    if( selectedUser?.['email'] && (!personDetails['email'] || personDetails['email'] !== selectedUser['email'])){
      setPersonDetails( { ... selectedUser});
    }
  }, [selectedUser, personDetails]);

  const {
    loading,
    personTypes
  } = usePersonTypes();
  
  const{
    persons
  } = usePersons(['MENTOR']);
  
  const{
    persons : coachPersons
  } = usePersons(['COACH']);
  
  
  const{
    schools
  } = useSchools();


  useEffect(() => {

    if (!upsertPersonCognitoError && !upsertPersonCognitoLoading && upsertPersonCognitoData && upsertPersonCognitoData.personUpsertWithCognito.body.upsertResult?.[0]?.row && personDetails['school']) {
        let newPerson = JSON.parse(upsertPersonCognitoData.personUpsertWithCognito.body.upsertResult[0]?.row)

        const personSchoolParams = {
          personId: newPerson.id,
          schoolId: schools.find((s) => s.title === personDetails['school'])?.id,
        }

        upsertPersonSchool(buildPersonSchoolUpsertVariables([personSchoolParams]))
    }

  }, [upsertPersonCognitoError, upsertPersonCognitoData, upsertPersonCognitoLoading, personDetails, schools, upsertPersonSchool]);



  const handleUserTypeChange = (event) => {
    setPersonType(event.target.value);
    setPersonDetails(() => {
      setIsFormValid(false);
      return {};
    });
  };

  const handleFieldChange = (question, value) => {

      setPersonDetails((prevState) => {
        const newFields = {
          ...prevState,
          [question]: value
        };

        setIsFormValid(
          (
            (!!newFields['email'] && newFields['email']?.trim() !== '' && isValidEmail(newFields['email'])) &&
            (!!newFields['givenName'] && newFields['givenName']?.trim() !== '' )&&
            (!!newFields['surname'] && newFields['surname']?.trim() !== '' )&&
            (!!newFields['gender']) 
          ) && (
            (newPersonType !== IdentityGroupType.Intern) || 
            (
              !!newFields['yearOfStudy'] &&
              !!newFields['school'] &&
              !!newFields['teachingPhaseMnemonic'] &&
              !!newFields['onlinementor'] &&
              !!newFields['onsitementor']
            )
          )
        );
  
        return newFields;
  
      });
  };

  const handleOnCreate = () => {  
    let upsertProperties = {
      id: personDetails['id'],
      gender: personDetails['gender'],
      telNo: personDetails['telNo'],
      givenName: personDetails['givenName'],
      surname: personDetails['surname'],
      email: personDetails['email'],
      personTypeId: personTypes.find((p) => p.title === newPersonType)?.id  ,
      active: personDetails['active'],
    }

    if( newPersonType === IdentityGroupType.Intern || mode === 'edit'){
        upsertProperties = {
          yearOfStudy: personDetails['yearOfStudy'],
          mentorPersonId: persons.find((m) => `${m.givenName} ${m.surname}` === personDetails['onsitementor'])?.id,
          coachPersonId: coachPersons.find((m) => `${m.givenName} ${m.surname}` === personDetails['onlinementor'])?.id,
          teachingPhaseMnemonic: personDetails['teachingPhaseMnemonic'],
            ...upsertProperties
        }
    }

    if( mode === 'edit'){
      upsertPersonById(buildPersonUpsertVariables([upsertProperties]));
    }else{
      upsertPersonCognito(buildPersonUpsertVariables([upsertProperties]));
    }
  }




  if (loading || upsertPersonCognitoLoading || upsertPersonSchoolLoading || upsertPersonLoading) {
    return (
    <VContainerVH>
      <Spinner text='Loading...'/>
    </VContainerVH>
    );
  }
  

  return (
    
  
    <React.Fragment>
      { 
        (
          (!upsertPersonCognitoLoading &&  upsertPersonCognitoData?.personUpsertWithCognito?.body?.upsertResult?.[0]?.ok == true) ||
          (!upsertPersonLoading && upsertPersonData?.personUpsert?.body?.upsertResult?.[0]?.ok == true)
        )
      &&
        <Feedback 
          imageUrl={Asset.Image.Other.BookStack}
          headerText={userFormModes[mode].success.headerText}
          bodyText={userFormModes[mode].success.bodyText}
          infoText={userFormModes[mode].success.infoText}
          buttonText='Go Back'
          buttonAction={() => navigate('/home')}/>
      }


    { (!loading &&
      !upsertPersonCognitoLoading && 
      !upsertPersonSchoolLoading  && 
      !upsertPersonSchoolData && 
      !upsertPersonLoading && 
      !upsertPersonData &&
      schools?.length > 0 ) && 

        <React.Fragment>
          <StyledHeadline> {userFormModes[mode].headers.main}</StyledHeadline>

          <StyledBody>{userFormModes[mode].headers.secondary} </StyledBody>

          {!upsertPersonCognitoLoading && upsertPersonCognitoData?.personUpsertWithCognito?.body?.ok == false &&
            <React.Fragment>
              <StyledErrorBody>
                <TitleMedium color='error'>
                  {upsertPersonCognitoData.personUpsertWithCognito.body.message ? upsertPersonCognitoData.personUpsertWithCognito.body.message : "We encountered a hiccup while creating the user." } 
                </TitleMedium>
              </StyledErrorBody>
            </React.Fragment>
          } 

          {(!upsertPersonLoading && upsertPersonData?.personUpsert?.body?.ok == false) && 
            <React.Fragment>
              <StyledErrorBody>
                <TitleMedium color='error'>
                  {upsertPersonData?.personUpsert.body.message ? upsertPersonData?.personUpsert.body.message : "We encountered a hiccup while updating the user." } 
                </TitleMedium>
              </StyledErrorBody>
            </React.Fragment>
          }

          {mode == 'create' &&
              <StyledFormControlDivider>
                <InfoText title="Select the type of user to add" />
                <RadioGroup
                  name="radio-buttons-group-user-type"
                  value={newPersonType} 
                  onChange={handleUserTypeChange}
                >
                  {
                    personTypes.map((type) => (
                      <FormControlLabel key={type.id} value={type.title} control={<Radio />} label={type.title} />
                    ))
                  }
                </RadioGroup>
              </StyledFormControlDivider>
          }

          { ((mode == 'create' && newPersonType?.length) || mode == 'edit') > 0 &&
          <React.Fragment>

          <ShortText
              key='personEmail'
              title='Email of user'
              placeholder='Email'
              label='Email'
              disabled={mode === 'edit'}
              value={personDetails.email}
              onChange={(event) => {
                handleFieldChange('email', event.target.value);
              }}
          />

          <ShortText
              key='personGivenName'
              title='First Name'
              placeholder='First name of user'
              label='First name'
              value={personDetails['givenName']}
              onChange={(event) => {
                handleFieldChange('givenName', event.target.value);
              }}
          />

          <ShortText
              key='personSurname'
              title='Surname'
              label='Surname'
              value={personDetails.surname}
              onChange={(event) => {
                handleFieldChange('surname', event.target.value);
              }}
          />

          <Dropdown
            key='personGender'
            title='Gender'
            label='Gender'
            placeholder='Gender'
            options={['male','female','other']}
            value={personDetails.gender}
            onChange={(event) => {
              handleFieldChange('gender', event.target.value);
            }}
          />
          </React.Fragment>
          }

          {  (newPersonType === IdentityGroupType.Intern || mode === 'edit') &&

            <React.Fragment>
                <Dropdown
                  key='internSchool'
                  title='Select School Associated with this Student'
                  label='School'
                  placeholder='School'
                  options={schools.map(s => `${s.title}`)}
                  value={personDetails.school}
                  disabled={mode === 'edit'}
                  onChange={(event) => {
                    handleFieldChange('school', event.target.value);
                  }}
                />

                <Dropdown
                  key='personYearOfStudy'
                  title='Year of study'
                  placeholder='1'
                  label='Year of study'
                  options={['1','2','3','4']}
                  value={personDetails.yearOfStudy}
                  onChange={(event) => {
                    handleFieldChange('yearOfStudy', event.target.value);
                  }}
                />

                <Dropdown
                  key='personTeachingPhase'
                  title='Teaching Phase'
                  label='Teaching Phase'
                  placeholder='Teaching Phase'
                  options={[TeachingPhaseMnemonic.Foundation, TeachingPhaseMnemonic.Intermediate]}
                  value={personDetails.teachingPhaseMnemonic}
                  onChange={(event) => {
                    handleFieldChange('teachingPhaseMnemonic', event.target.value);
                }}
                />
                
                <Dropdown
                  key='internRelatedOnSiteMentor'
                  title='Select On-Site Mentor Associated with this Student'
                  label='On-Site Mentor'
                  placeholder='On-Site Mentor'
                  options={persons.map(p => `${p.givenName} ${p.surname}`)}
                  value={personDetails.onsitementor}
                  onChange={(event) => {
                    handleFieldChange('onsitementor', event.target.value);
                  }}
                />

                <Dropdown
                  key='internRelatedOnlineMentor'
                  title='Select Online Mentor Associated with this Student'
                  label='Online Mentor'
                  placeholder='Online Mentor'
                  options={coachPersons.map(p => `${p.givenName} ${p.surname}`)}
                  value={personDetails.onlinementor}
                  onChange={(event) => {
                    handleFieldChange('onlinementor', event.target.value);
                  }}
                />

                <Dropdown
                  key='active'
                  title='Active'
                  placeholder='true'
                  label='Active'
                  options={['Yes', 'No']}
                  value={personDetails.active === true ? 'Yes' : 'No'}
                  onChange={(event) => {
                    handleFieldChange('active', event.target.value === 'Yes' ? true : false);
                  }}
                />

                  </React.Fragment>
              }
                  <HFillContainerV>
                    <PrimaryButton
                      text='Submit'
                      onClick={() => handleOnCreate()}
                      disabled={!isFormValid}
                    />
                  </HFillContainerV>

        </React.Fragment>
    }

    </React.Fragment>

  );
};

User.displayName = 'User';

User.propTypes = {
  mode: PropTypes.string.isRequired,
  selectedUser: PropTypes.any,
};

User.defaultProps = {
  mode: 'create',
  selectedUser: undefined,
};
