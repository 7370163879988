import {
  EmailOutlined,
  BusinessOutlined,
  PhoneOutlined,
} from '@mui/icons-material';

import { IdentityGroupType } from 'enums';

export const contactInformation = [
  {
    icon: PhoneOutlined,
    type: 'Contact Number',
    contact: '+27 (0) 72 298 6018',
    additonal: 'Mon - Fri 08:00 – 17:00',
  },
  {
    icon: EmailOutlined,
    type: 'Email Address',
    contact: 'desmond.macauley@thutotrust.org',
    additonal: 'Support & information',
  },
  {
    icon: BusinessOutlined,
    type: 'Physical Address',
    contact: '89 Bute Road Sandown',
    additonal: 'Sandton, 2196',
  },
  
]

export const helpTabs = [
  { label: 'FAQ' },
  { label: 'Support' },
];

const generalFAQdata = [
  {
    question: "How do I download and install the app on my phone or laptop?",
    answer: `Creating a shortcut for the Thuto web application on your devices can make it easier and quicker to access. 
      Here’s how you can create a shortcut on Chrome for Windows and Android, as well as Safari for iOS and macOS:
      
      <br><h4>Chrome on Windows</h4>

      1. <b>Open Chrome:</b> Launch the Chrome browser on your Windows device.
      
      <br>2. <b>Go to the Thuto App:</b> Type <a style="color: #3CAA49;" href="https://thutoapp.org/">https://thutoapp.org/</a> in the address bar and press Enter.

      <br>3. <b>Create a Shortcut:</b>

      <br>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Click the three dots in the upper-right corner of Chrome to open the menu.</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Go to “Save and share” > “Create shortcut.”</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Name the shortcut (e.g., "Thuto App") and click “Create.”</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">This will add the shortcut to your desktop</div>
      </div>
      
      <h4>Chrome on Android</h4>

      1. <b>Open Chrome:</b> Launch the Chrome browser on your Windows device.
      
      <br>2. <b>Go to the Thuto App:</b> Enter <a style="color: #3CAA49;" href="https://thutoapp.org/">https://thutoapp.org/</a> in the address bar.

      <br>3. <b>Add to Home Screen:</b>

      <br>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Tap the three dots in the upper-right corner to open the menu.</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Tap “Install app.”</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Name your shortcut (e.g., "Thuto App") and tap “Add.”</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">The shortcut will appear on your home screen.</div>
      </div>

      <h4>Safari on iOS</h4>

      1. <b>Open Safari:</b> Launch Safari on your iOS device.
      
      <br>2. <b>Go to the Thuto App:</b> Enter <a style="color: #3CAA49;" href="https://thutoapp.org/">https://thutoapp.org/</a> in the address bar.

      <br>3. <b>Add to Home Screen:</b>

      <br>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Tap the share icon (the square with an arrow pointing out of it) at the bottom of the screen.</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Scroll through the options and tap “Add to Home Screen.”</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Enter the name for the shortcut (e.g., "Thuto App") and tap “Add.”</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">The shortcut will be added to your home screen.</div>
      </div>

      <h4>Safari on macOS</h4>

      1. <b>Open Safari:</b> Open Safari on your macOS device.
      
      <br>2. <b>Go to the Thuto App:</b> Visit <a style="color: #3CAA49;" href="https://thutoapp.org/">https://thutoapp.org/</a>.

      <br>3. <b>Create a Shortcut:</b>

      <br>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Drag the URL from the address bar to your desktop, or</div>
      </div>
      <div style="display: flex;">
        <div style="flex: 0; padding-left: 0.5rem;"> - </div>
        <div style="padding-left: 0.5rem;">Go to the File menu at the top of the screen, select “Share” > “Add to Dock,” which will create a shortcut in your Dock for easy access.</div>
      </div>
      `
  },
  {
    question: "Is the app data intensive?",
    answer: "The Thuto App is designed to be efficient with data usage. Most features are usable with minimal data consumption."
  },
  {
    question: "Can I access the app offline?",
    answer: `Yes, should you lose connectivity while using the app, your progress and answers will be saved to the current device.
      You can continue working, and your changes will be preserved until you reconnect to the internet.`
  },
];

const internFAQData = [
  {
    question: "How do I add a new task to my schedule?",
    answer: `To add a new task, navigate to the 'Add Task' section, select the type of task you wish to schedule,
    fill in the necessary details like dates and times, and submit.
    The task will then be added to 'Your Tasks' on your Dashboard where you can manage it further.`
  },
  {
    question: "What should I do if I forget to check in or out?",
    answer: `If you've missed a check-in or out, promptly inform your mentor via the app's comment feature.
    You may also submit a late check-in or out within the task, but please note, late submissions will be recorded.`
  },
  {
    question: "Can I customize lesson plans within the app?",
    answer: `Yes, the app allows for full customization of lesson plans.
    You can create your template or modify existing ones to fit your teaching style and meet the curriculum requirements.`
  },
  {
    question: "What happens if I can't complete a task on time?",
    answer: `If you're unable to complete a task by its due date,
    you should immediately communicate with your mentor via the Task Comment section to discuss an extension.`
  },
  {
    question: "What happens if I can't complete a task on time?",
    answer: `Procedures for missed deadlines and how to communicate with mentors about extensions or modifications to the task due dates.
    Explanation of potential impacts on task status and how to access support for time management strategies within the app.`
  },
  {
    question: "How can I view feedback from my mentor?",
    answer: `When your mentor has provided feedback on a specific task, a red notice badge will appear on the task card.
    By opening the task and navigation to the comment section, you’ll find your mentor’s feedback.`
  },
];

const mentorFAQData = [
  {
    question: "How do I provide feedback on submitted tasks?",
    answer: `To provide feedback, navigate to the ‘Tasks’ section on your dashboard.
    Select the student's task you wish to review. You can enter your feedback directly into the comment section, focusing on constructive criticism that encourages development.
    Remember to highlight what they did well and offer specific guidance for improvement.`
  },
  {
    question: "What should I do if I notice a student struggling with a particular task?",
    answer: `If a student appears to be struggling, and you wish to reach out to offer support, you can use the comment feature to message them in relation to a specific task they’re struggling with.
    Alternatively, their contact details can be found in their profile. You may suggest additional resources or arrange a one-on-one meeting to discuss the challenges they're facing.`
  },
  {
    question: "How do I schedule lesson observations?",
    answer: `To schedule lesson observations, use the 'Create Lesson Observation' feature when reviewing a Student’s Lesson Plan.
    The Lesson Observation task will be added to your schedule.`
  },
  {
    question: "Is there a way to track a student's overall progress?",
    answer: `Track a student's progress using the ‘Task List’ where you can view their tasks in a three-week window.
    This dashboard offers a comprehensive view of the student's recent tasks. If you’d like to access a more in-depth view,
    please contact your administrator to export a report on the selected student.`
  },
];

const privacyFAQdata = [
  {
    question: "How can I ensure the privacy of my data within the app?",
    answer: `The app is built with your privacy in mind.
    It employs state-of-the-art encryption for data storage and transmission, user-specific access controls to ensure that information is only accessible to authorized users,
    and adheres to strict privacy policies. You can review our detailed privacy policy in the app settings to understand how we protect your data.`
  },
  {
    question: "How do I report issues or get technical assistance?",
    answer: `If you encounter any technical issues, you can easily contact our support team directly through the app's 'Help & FAQ' page.
    Submit a ticket anytime, and we'll get back to you promptly. For urgent matters, please use our dedicated support line and email for direct access.`
  },
];

export const getFAQData = (userType) => {
  if (userType === IdentityGroupType.Intern) {
    return generalFAQdata.concat(internFAQData).concat(privacyFAQdata);
  }

  return generalFAQdata.concat(mentorFAQData).concat(privacyFAQdata);
}
