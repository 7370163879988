import {
  IdentityGroupType,
  StatusMnemonic,
  TaskTypeMnemonic,
} from 'enums';

import {
  TasksPerPage,
} from 'config';

export const taskListWeeklyPlansQueryParams = (userId, dateRange) => {
  return {
    params: {
      filterProperties: [
        {
          operator: 'IN',
          fieldNames: ['ownerPersonId'],
          value: [userId],
        },
        {
          operator: 'OVERLAP',
          fieldNames: [
            'scheduledStartTimestamp',
            'scheduledEndTimestamp',
          ],
          value: dateRange,
        },
        {
          operator: 'IN',
          fieldNames: [
            'taskTemplateMnemonic',
          ],
          value: TaskTypeMnemonic.InternPlanUpcomingWeek,
        },
      ],
      filterLogicGate: 'AND',
    },
  };
};

/**
 * Convenience function to get the query params to retrieve the tasks for the specified user.
 * The params differ slightly depending on the user type.
 * @param {string} userId The ID of the user.
 * @param {IdentityGroupType} userType The type of the specified user. Slightly different query
 * params are required depending on the user type.
 * @param {[string, string]} dateRange A tuple of date strings to limit the date range of the
 * results.
 * @param {number} page The page of results to query. **Numbering starts at 1**.
 * @returns {Object} The query params.
 */
export const tasksQueryParams = ({
  userId,
  userType,
  dateRange,
  page,
}) => {
  switch (userType) {
    case IdentityGroupType.Mentor:
    case IdentityGroupType.Coach:
      return tasksMentorQueryParams({
        userId,
        dateRange,
        page,
      });

    default:
      return tasksInternQueryParams({
        userId,
        dateRange,
        page,
      });
  }
};

/**
 * Get the query params to only retrieve the student check-in and out tasks.
 * @param {string} userId The ID of the user.
 * @param {[string, string]} dateRange A tuple of date strings to limit the date range of the
 * @returns {Object} The query params.
 */
export const tasksInternCheckInAndOutParams = ({
  userId,
  dateRange,
}) => {
  return {
    params: {
      filterProperties: [
        {
          operator: 'IN',
          fieldNames: ['ownerPersonId'],
          value: [userId],
        },
        {
          operator: 'IN',
          fieldNames: ['taskTemplateMnemonic'],
          value: [
            TaskTypeMnemonic.InternCheckin,
            TaskTypeMnemonic.InternCheckout,
          ],
        },
        {
          operator: 'IN',
          fieldNames: ['weeklyPlanTaskApproved'],
          value: [
            'true',
          ],
        },
        {
          operator: 'OVERLAP',
          fieldNames: [
            'scheduledStartTimestamp',
            'scheduledEndTimestamp',
          ],
          value: dateRange,
        },
      ],
    },
  };
};

export const tasksInternGetAssessmentsParams = ({
  userId,
  dateRange,
}) => {
  return {
    params: {
      filterProperties: [
        {
          operator: 'IN',
          fieldNames: ['ownerPersonId'],
          value: [userId],
        },
        {
          operator: 'BETWEENTIME',
          fieldNames: ['createdTimestamp'],
          value: dateRange,
        },
        {
          operator: 'NOTNULL',
          fieldNames: ['assessmentMnemonic'],
          value: [],
        },
        {
          operator: 'IN',
          fieldNames: ['statusMnemonic'],
          value: [
            StatusMnemonic.Completed,
            StatusMnemonic.Approved,
          ],
        },
      ],
      orderBy: [
        {
          fieldNames: [
            'createdTimestamp',
          ],
          direction: 'DESC',
        },
      ],
    },
  };
};

export const tasksInternCompletedTaskParams = ({
  userId,
  dateRange,
  taskMnemonics,
}) => {
  return {
    params: {
      filterProperties: [
        {
          operator: 'IN',
          fieldNames: ['ownerPersonId'],
          value: [userId],
        },
        {
          operator: 'BETWEENTIME',
          fieldNames: ['createdTimestamp'],
          value: dateRange,
        },
        {
          operator: 'IN',
          fieldNames: ['taskTemplateMnemonic'],
          value: taskMnemonics,
        },
        {
          operator: 'IN',
          fieldNames: ['statusMnemonic'],
          value: [
            StatusMnemonic.Completed,
            StatusMnemonic.Approved,
          ],
        },
      ],
      orderBy: [
        {
          fieldNames: [
            'createdTimestamp',
          ],
          direction: 'DESC',
        },
      ],
    },
  };
};

/**
 * Get the query params to retrieve the tasks for an *student*.
 * @param {string} userId The ID of the user.
 * @param {[string, string]} dateRange A tuple of date strings to limit the date range of the
 * results.
 * @param {number} page The page of results to query. **Numbering starts at 1**.
 * @returns {Object} The query params.
 */
export const tasksInternQueryParams = ({
  userId,
  dateRange,
  page,
}) => {
  return {
    params: {
      filterProperties: [
        {
          operator: 'IN',
          fieldNames: ['ownerPersonId'],
          value: [userId],
        },
        {
          operator: 'IN',
          fieldNames: ['weeklyPlanTaskApproved'],
          value: ['true'],
        },
        {
          operator: 'OVERLAP',
          fieldNames: [
            'scheduledStartTimestamp',
            'scheduledEndTimestamp',
          ],
          value: dateRange,
        },
      ],
      orderBy: [
        {
          fieldNames: [
            'scheduledEndTimestamp',
          ],
          direction: 'ASC',
        },
        {
          fieldNames: [
            'taskTemplateMnemonic',
          ],
          direction: 'WEIGHTEDASC',
          values: [
            {
              value: 'INTERNCHKOUT',
              weight: 2,
            },
            {
              value: 'INTERNCHKIN',
              weight: 1,
            },
          ],
        },
        {
          fieldNames: [
            'statusMnemonic',
          ],
          direction: 'WEIGHTEDASC',
          values: [
            {
              value: StatusMnemonic.Completed,
              weight: 6,
            },
            {
              value: StatusMnemonic.Scheduled,
              weight: 5,
            },
            {
              value: StatusMnemonic.Open,
              weight: 4,
            },
            {
              value: StatusMnemonic.InProgress,
              weight: 3,
            },
            {
              value: StatusMnemonic.Review,
              weight: 2,
            },
            {
              value: StatusMnemonic.Declined,
              weight: 1,
            },
          ],
        },
        {
          fieldNames: [
            'scheduledStartTimestamp',
          ],
          direction: 'ASC',
        },
        {
          fieldNames: [
            'createdTimestamp',
          ],
          direction: 'DESC',
        },
      ],
      first: TasksPerPage,
      offset: Math.max(page - 1, 0) * TasksPerPage,
    },
  };
};

/**
 * Get the query params to retrieve the tasks for an *online or on-site mentor*.
 * @param {string} userId The ID of the user.
 * @param {[string, string]} dateRange A tuple of date strings to limit the date range of the
 * results.
 * @param {number} page The page of results to query. **Numbering starts at 1**.
 * @returns {Object} The query params.
 */
export const tasksMentorQueryParams = ({
  userId,
  dateRange,
  page,
}) => ({
  params: {
    filterProperties: [
      {
        operator: 'IN',
        fieldNames: ['ownerPersonId'],
        value: [userId],
      },
      {
        operator: 'OVERLAP',
        fieldNames: [
          'scheduledStartTimestamp',
          'scheduledEndTimestamp',
        ],
        value: dateRange,
      },
    ],
    orderBy: [
      {
        fieldNames: [
          'scheduledEndTimestamp',
        ],
        direction: 'ASC',
      },
      {
        fieldNames: [
          'statusMnemonic',
        ],
        direction: 'WEIGHTEDASC',
        values: [
          {
            value: StatusMnemonic.Completed,
            weight: 6,
          },
          {
            value: StatusMnemonic.Scheduled,
            weight: 5,
          },
          {
            value: StatusMnemonic.Open,
            weight: 4,
          },
          {
            value: StatusMnemonic.InProgress,
            weight: 3,
          },
          {
            value: StatusMnemonic.Review,
            weight: 2,
          },
          {
            value: StatusMnemonic.Rejected,
            weight: 1,
          },
        ],
      },
      {
        fieldNames: [
          'scheduledStartTimestamp',
        ],
        direction: 'ASC',
      },
      {
        fieldNames: [
          'createdTimestamp',
        ],
        direction: 'DESC',
      },
    ],
    first: TasksPerPage,
    offset: Math.max(page - 1, 0) * TasksPerPage,
  },
});

/**
 * Get the query params to retrieve the tasks that require action from an *online mentor*.
 * @param {string} userId The ID of the user.
 * @param {[string, string]} dateRange A tuple of date strings to limit the date range of the
 * results.
 * @param {number} page The page of results to query. **Numbering starts at 1**.
 * @returns {Object} The query params.
 */
export const tasksOnlineMentorActionQueryParams = ({
  userId,
  dateRange,
  page,
}) => ({
  params: {
    filterProperties: [
      {
        operator: 'NOTIN',
        fieldNames: ['ownerPersonId'],
        value: [userId],
      },
      {
        operator: 'IN',
        fieldNames: ['statusMnemonic'],
        value: [
          StatusMnemonic.Review,
          StatusMnemonic.AwaitCoach,
        ],
      },
      {
        operator: 'IN',
        fieldNames: ['taskTemplateMnemonic'],
        value: [
          TaskTypeMnemonic.InternLessonPlan,
          TaskTypeMnemonic.InternLessonReflection,
        ],
      },
      {
        operator: 'IN',
        fieldNames: ['weeklyPlanTaskApproved'],
        value: ['true'],
      },
      {
        operator: 'OVERLAP',
        fieldNames: [
          'scheduledStartTimestamp',
          'scheduledEndTimestamp',
        ],
        value: dateRange,
      },
    ],
    orderBy: [
      {
        fieldNames: [
          'scheduledEndTimestamp',
        ],
        direction: 'ASC',
      },
      {
        fieldNames: [
          'statusMnemonic',
        ],
        direction: 'WEIGHTEDASC',
        values: [
          {
            value: StatusMnemonic.AwaitCoach,
            weight: 1,
          },
          {
            value: StatusMnemonic.Review,
            weight: 2,
          },
        ],
      },
      {
        fieldNames: [
          'scheduledStartTimestamp',
        ],
        direction: 'ASC',
      },
      {
        fieldNames: [
          'createdTimestamp',
        ],
        direction: 'DESC',
      },
    ],
    first: TasksPerPage,
    offset: Math.max(page - 1, 0) * TasksPerPage,
  },
});

/**
 * Get the query params to retrieve the tasks that require action from an *on-site mentor*.
 * @param {string} userId The ID of the user.
 * @param {[string, string]} dateRange A tuple of date strings to limit the date range of the
 * results.
 * @param {number} page The page of results to query. **Numbering starts at 1**.
 * @returns {Object} The query params.
 */
export const tasksOnsiteMentorActionQueryParams = ({
  userId,
  dateRange,
  page,
}) => ({
  params: {
    filterProperties: [
      {
        operator: 'NOTIN',
        fieldNames: ['ownerPersonId'],
        value: [userId],
      },
      {
        operator: 'IN',
        fieldNames: ['statusMnemonic'],
        value: [
          'REVIEW',
          'AWAIT MENTOR RESPONSE',
        ],
      },
      {
        operator: 'NOTIN',
        fieldNames: ['taskTemplateMnemonic'],
        value: [TaskTypeMnemonic.InternLessonPlan],
      },
      {
        operator: 'OVERLAP',
        fieldNames: [
          'scheduledStartTimestamp',
          'scheduledEndTimestamp',
        ],
        value: dateRange,
      },
    ],
    orderBy: [
      {
        fieldNames: [
          'scheduledEndTimestamp',
        ],
        direction: 'ASC',
      },
      {
        fieldNames: [
          'statusMnemonic',
        ],
        direction: 'WEIGHTEDASC',
        values: [
          {
            value: StatusMnemonic.AwaitMentor,
            weight: 1,
          },
          {
            value: StatusMnemonic.Review,
            weight: 2,
          },
        ],
      },
      {
        fieldNames: [
          'scheduledStartTimestamp',
        ],
        direction: 'ASC',
      },
      {
        fieldNames: [
          'createdTimestamp',
        ],
        direction: 'DESC',
      },
    ],
    first: TasksPerPage,
    offset: Math.max(page - 1, 0) * TasksPerPage,
  },
});

/**
 * Get the query params to retrieve the lesson presentations that need to be reviewed by an
 * *on-site mentor*.
 * @param {string} userId The ID of the user.
 * @param {[string, string]} dateRange A tuple of date strings to limit the date range of the
 * results.
 * @param {number} page The page of results to query. **Numbering starts at 1**.
 * @returns {Object} The query params.
 */
export const tasksLessonPresentationQueryParams = ({
  userId,
  dateRange,
  page,
}) => ({
  params: {
    filterProperties: [
      {
        operator: 'NOTIN',
        fieldNames: ['ownerPersonId'],
        value: [userId],
      },
      {
        operator: 'IN',
        fieldNames: ['statusMnemonic'],
        value: [
          'OPEN',
          'SCHEDULED',
        ],
      },
      {
        operator: 'IN',
        fieldNames: ['taskTemplateMnemonic'],
        value: [TaskTypeMnemonic.InternLessonPresentation],
      },
      {
        operator: 'IN',
        fieldNames: ['weeklyPlanTaskApproved'],
        value: ['true'],
      },
      {
        operator: 'OVERLAP',
        fieldNames: [
          'scheduledStartTimestamp',
          'scheduledEndTimestamp',
        ],
        value: dateRange,
      },
    ],
    orderBy: [
      {
        fieldNames: [
          'scheduledStartTimestamp',
        ],
        direction: 'ASC',
      },
    ],
    first: TasksPerPage,
    offset: Math.max(page - 1, 0) * TasksPerPage,
  },
});
