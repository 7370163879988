import React from 'react';

import {
  useSearchParams,
} from 'react-router-dom';

import {
  IconButton,
  InputAdornment,
  Pagination,
  styled,
  TextField as TextFieldProto,
} from '@mui/material';

import {
  Search,
} from '@mui/icons-material';

import {
  IdentityGroupType,
  JourneyName,
  ScreenName,
} from 'enums';

import {
  useIdentity,
  usePeopleList,
} from 'hooks';

import {
  BodyMedium,
  HContainerV,
  HeadlineLarge,
  InternCard,
  PrimaryButton,
  Spinner,
  TitleLarge,
  TitleMedium,
  VContainer,
} from 'ui/common';

import {
  TaskTabs,
} from 'ui/task-list/TaskTabs';

const StyledHeadline = styled(HeadlineLarge)`
  margin-top: 2rem;
`;

const StyledBody = styled(BodyMedium)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const StyledTitle = styled(TitleLarge)`
  margin-bottom: 1rem;
`;

const StyledNoMatchBody = styled(BodyMedium)`
  margin-bottom: 1rem;
`;

const StyledListTitle = styled(TitleMedium)`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const StyledPagination = styled(Pagination)`
  margin-top: 2rem;
`;

const StyledTextField = styled(TextFieldProto)(({ theme }) => ({
  backgroundColor: theme.palette.primary.p100,
  width: '100%',
  marginBottom: '16px',
  border: 'none',
  borderRadius: '28px',
  padding: '0 0.25rem',
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.primary.p100,
    borderRadius: '28px',
  },
}));

const ClearSearchButton = styled(PrimaryButton)`
  @media (min-width: 600px) {
    max-width: 228px;
  }
`;

export const InternTasks = () => {

  const { user } = useIdentity(InternTasks.context);
  const userType = user.group || IdentityGroupType.Mentor;
  const [searchParams] = useSearchParams();
  const [page, setPage] = React.useState(1);
  const [searchInput, setSearchInput] = React.useState('');

  const internId = searchParams.get('id');

  const {
    loading,
    peopleList: internList,
    pageInfo,
  } = usePeopleList({
    userId: user.id,
    userType,
    page,
  });

  const deferredSearchInput = React.useDeferredValue(searchInput);

  const filterInterns = React.useMemo(() => {

    if (loading || !Array.isArray(internList)) {
      return [];
    }

    if (!deferredSearchInput) {
      return internList;
    }

    return internList.filter(intern =>
      intern.fullName?.toLowerCase()?.includes(deferredSearchInput.toLowerCase()) ||
      intern.school?.toLowerCase()?.includes(deferredSearchInput.toLowerCase()),
    );
  }, [
    deferredSearchInput,
    internList,
    loading,
  ]);

  const renderPersonList = () => {

    const hasInterns = Array.isArray(filterInterns) && filterInterns.length > 0;

    return (
      <React.Fragment>

        <StyledListTitle>
          Select a person
        </StyledListTitle>

        <VContainer>

          {hasInterns && filterInterns.map((intern) => (

            <InternCard
              key={intern.id}
              id={intern.id}
              name={intern.fullName}
              personType={user.group === IdentityGroupType.Admin ? intern.personType : null}
              school={intern.school || 'School'}
            />
          ))}

          {!hasInterns &&

            <React.Fragment>

              <StyledTitle
                color={'secondary'}>

                {
                  pageInfo.totalPages > 1
                    ? 'No Matches Found For This Page'
                    : 'No Matches Found'
                }
              </StyledTitle>

              <StyledNoMatchBody>
                We couldn’t find any students or schools matching your search.
              </StyledNoMatchBody>

              <StyledNoMatchBody>
                Please check your spelling, try different keywords, or adjust your search
                criteria.
              </StyledNoMatchBody>

              <ClearSearchButton
                text={'Clear Search'}
                onClick={() => setSearchInput('')}/>
            </React.Fragment>
          }

          {pageInfo.totalPages > 1 &&

            <StyledPagination
              count={pageInfo.totalPages}
              page={page}
              onChange={(_event, page) => {
                setPage(page);
              }}/>
          }
        </VContainer>
      </React.Fragment>
    );
  };

  return (

    <React.Fragment>

      <VContainer>

        {!internId && user.group === IdentityGroupType.Admin &&

          <StyledHeadline>
            SYSTEM USERS
          </StyledHeadline>
        }

        {!internId && user.group !== IdentityGroupType.Admin &&

          <StyledHeadline>
            YOUR STUDENTS
          </StyledHeadline>
        }

        {!internId &&

          <StyledBody>
            Choose a person to view their complete record of tasks, submissions, and feedback for
            last, current and next week.
          </StyledBody>
        }

        {!!internId &&

          <StyledHeadline>
            TASK LIST
          </StyledHeadline>
        }

        {!!internId && internList && user.group !== IdentityGroupType.Intern &&

          <StyledBody>
            Viewing {internList?.find((user) => user.id === internId).fullName}'s complete record of
            tasks, submissions, and feedback for their last, current and next week.
          </StyledBody>
        }
      </VContainer>

      {!!internId &&

        <TaskTabs
          user={user}
          targetUser={{
            id: internId,
            group: IdentityGroupType.Intern,
          }}/>
      }

      {!internId &&

        <React.Fragment>

          <HContainerV>

            <StyledTextField
              label="Search for a person"
              placeholder="e.g. Thandi"
              variant="filled"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                disableUnderline: true,
                endAdornment:
                  <InputAdornment position="end">

                    <IconButton
                      aria-label="search icon"
                      edge="end">

                      <Search/>
                    </IconButton>
                  </InputAdornment>,
              }}
            />
          </HContainerV>

          {!!loading &&
            <Spinner/>
          }

          {!loading && renderPersonList()}
        </React.Fragment>
      }
    </React.Fragment>
  );
};

InternTasks.displayName = 'InternTasks';

InternTasks.context = {
  screen: ScreenName.InternTasks,
  journey: JourneyName.InternTasks,
  component: InternTasks.displayName,
};
