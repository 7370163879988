import { IdentityGroupType, InputTypeMnemonic, QuestionMnemonic, StatusMnemonic } from "enums";

import { isValidEmail } from "./validation";

import {
  isAfter,
  isBefore,
  previousSunday,
} from 'date-fns';

export const validateForm = (formState, questions) => {
  const validation = {};

  questions.forEach((q) => {
    const result = isQuestionValid(q, formState[q.id]);

    validation[q.id] = {
      isHidden: checkParentQuestion(q, formState),
      ...result,
    }
  });

  validation.isFormValid = !Object.entries(validation).some(([id, value]) => {
    if (id === 'isFormValid') {
      return false;
    }

    return value.hasError && !value.isHidden;
  });

  return validation;
};

const checkEmptyArray = (value) => {

  try {
    const parsedValue = JSON.parse(value);
    return Array.isArray(parsedValue) && parsedValue.length === 0;
  } catch {
    return false;
  }
}

const validateRatingLontextQuestion = (value) => {

  try {
    const parsedValue = JSON.parse(value);
    return !parsedValue.rating || !parsedValue.response || parsedValue.response.toString().trim() === '' 
  } catch {
    return false;
  }
}

const isQuestionValid = (question, currentValue) => {

  if (question.inputType === InputTypeMnemonic.RatingLongtext ||
      question.inputType === InputTypeMnemonic.InternEvalRating ||
      question.inputType === InputTypeMnemonic.MentorEvalRating ) {

    if(validateRatingLontextQuestion(currentValue)){
      return {
        hasError: true,
        errorText: 'This field is required',
      }
    }

    if(question.regex && currentValue) {
      const parsedValue = JSON.parse(currentValue);
      const expressionList = JSON.parse(question.regex);
      const textValue = parsedValue.response;

      for(let i = 0; expressionList.length > i; i++) {
        const regex = new RegExp(expressionList[i].expression);
        if (!regex.test(textValue)) {
          return {
            hasError: true,
            errorText: expressionList[i].message,
          }
        }
      }
    }
  }


  if (question.required && (currentValue == null || currentValue.toString().trim() === '' || checkEmptyArray(currentValue))) {
    return {
      hasError: true,
      errorText: 'This field is required',
    }
  }

  if (question.regex) {
    const expressionList = JSON.parse(question.regex);

    for(let i = 0; expressionList.length > i; i++) {
      const regex = new RegExp(expressionList[i].expression);
      if (!regex.test(currentValue)) {
        return {
          hasError: true,
          errorText: expressionList[i].message,
        }
      }
    }
  }

  return {
    hasError: false,
    errorText: '',
  };
};

export const checkParentQuestion = (question, formFields) => {
  return question.pqId && question.pqValue !== formFields[question.pqId] || false;
};

export const buildFormDetails = (details, localForm, internTask) => {
  if (details == null) {
    return;
  }

  const defaultFormState = localForm != null && JSON.parse(localForm) || {} ;

  details.questions?.forEach(q => {

    if (q.mnemonic === QuestionMnemonic.Grade) {
      defaultFormState[q.id] = q.answer && q.answer || undefined;
    } else if (defaultFormState[q.id] == null) {
      defaultFormState[q.id] = q.answer || q.default;
    }
  });

  if (internTask != null && internTask.calculatedFields.effectiveStatus === StatusMnemonic.Completed) {
    internTask.questions.forEach(q => {
      const targetMnemonic = q.mnemonic?.split('INTERN')[1];
      const targetQuestion = details.questions?.find(dq => dq.mnemonic?.includes(targetMnemonic));

      if (targetQuestion) {
        targetQuestion.ghostAnswer = q.answer;
      }
    });
  }

  details.defaultFormState = defaultFormState;
  details.defaultValidationState = validateForm(defaultFormState, details.questions);

  return details;
};

export const isReadOnlyFromTaskDetails = (details, user) => {

  return details.calculatedFields.effectiveStatus === StatusMnemonic.Completed ||
  (details.ownerPersonId !== user.id) ||
  details.calculatedFields.isMissed ||
  (details.calculatedFields.effectiveStatus === StatusMnemonic.InProgress &&
    !(details.status === StatusMnemonic.InProgress || details.status === StatusMnemonic.Rejected));
};

export const validateTaskForm = (newFields) => {

  const errorMap = {}

  if (newFields.title == null || newFields.title.trim() === '') {
    errorMap.title = {
      hasError: true,
      errorText: 'Title cannot be empty',
    }
  }

  if (newFields.scheduledStartTimestamp == null) {
    errorMap.scheduledStartTimestamp = {
      hasError: true,
      errorText: 'Start time cannot be empty.',
    }
  }

  if (newFields.scheduledEndTimestamp == null) {
    errorMap.scheduledEndTimestamp = {
      hasError: true,
      errorText: 'Start time cannot be empty.',
    }
  }

  if (isBefore(newFields.scheduledStartTimestamp, previousSunday(new Date()))) {
    errorMap.scheduledStartTimestamp = {
      hasError: true,
      errorText: 'Start time needs to be this week or later.',
    }
  }

  if (isAfter(newFields.scheduledStartTimestamp, newFields.scheduledEndTimestamp)) {
    errorMap.scheduledStartTimestamp = {
      hasError: true,
      errorText: 'Start time cannot be later than end time.',
    }
  }

  return errorMap;
}

export const validateSupportForm = (newFields) => {

  const errorMap = {}

  if (newFields.givenName == null || newFields.givenName.trim() === '') {
    errorMap.givenName = {
      hasError: true,
      errorText: 'First name cannot be empty',
    }
  }

  if (newFields.surname == null || newFields.surname.trim() === '') {
    errorMap.surname = {
      hasError: true,
      errorText: 'Surname cannot be empty',
    }
  }

  if (newFields.subject == null || newFields.subject.trim() === '') {
    errorMap.subject = {
      hasError: true,
      errorText: 'Subject cannot be empty',
    }
  }

  if (newFields.message == null || newFields.message.trim() === '') {
    errorMap.message = {
      hasError: true,
      errorText: 'Message cannot be empty',
    }
  }

  if (newFields.queryType == null || newFields.queryType.trim() === '') {
    errorMap.queryType = {
      hasError: true,
      errorText: 'Query type cannot be empty',
    }
  }

  if (newFields.email == null || !isValidEmail(newFields.email)) {
    errorMap.email = {
      hasError: true,
      errorText: 'Email address cannot be empty',
    }
  }

  return errorMap;
}

export const validateEditProfileForm = (newFields, userType) => {

  const errorMap = {}

  if (newFields.givenName == null || newFields.givenName.trim() === '') {
    errorMap.givenName = {
      hasError: true,
      errorText: 'Given Name cannot be empty',
    }
  }

  if (newFields.surname == null || newFields.surname.trim() === '') {
    errorMap.surname = {
      hasError: true,
      errorText: 'Surname cannot be empty',
    }
  }

  if (newFields.telNo == null || newFields.telNo.trim() === '') {
    errorMap.telNo = {
      hasError: true,
      errorText: 'Contact Number cannot be empty',
    }
  }

  if (userType === IdentityGroupType.Intern && (newFields.teachingPhaseMnemonic == null || newFields.teachingPhaseMnemonic.trim() === '')) {
    errorMap.teachingPhaseMnemonic = {
      hasError: true,
      errorText: 'Teaching Phase cannot be empty',
    }
  }

  return errorMap;
}

export const validateAssessmentForm = (newFields) => {

  const errorMap = {}

  const internsExist = newFields.interns !== null && newFields.interns.length > 0;
  const yearOfStudyExist = newFields.yearOfStudy !== null && newFields.yearOfStudy.length > 0;

  const atLeastOneExists = internsExist || yearOfStudyExist;

  if (newFields.scheduledStartTimestamp == null) {
    errorMap.scheduledStartTimestamp = {
      hasError: true,
      errorText: 'Start time cannot be empty.',
    }
  }

  if (newFields.scheduledEndTimestamp == null) {
    errorMap.scheduledEndTimestamp = {
      hasError: true,
      errorText: 'Start time cannot be empty.',
    }
  }

  if (isBefore(newFields.scheduledStartTimestamp, previousSunday(new Date()))) {
    errorMap.scheduledStartTimestamp = {
      hasError: true,
      errorText: 'Start time needs to be this week or later.',
    }
  }

  if (isAfter(newFields.scheduledStartTimestamp, newFields.scheduledEndTimestamp)) {
    errorMap.scheduledStartTimestamp = {
      hasError: true,
      errorText: 'Start time cannot be later than end time.',
    }
  }

  if(!atLeastOneExists) {
    errorMap.interns = {
      hasError: true,
      errorText: 'Student selection cannot be empty',
    }
  }

  if(newFields.indicators == null || newFields.indicators.length === 0) {
    errorMap.indicators = {
      hasError: true,
      errorText: 'Evaluation Indicator selection cannot be empty',
    }
  }

  return errorMap;
}