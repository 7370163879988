import {
  JourneyName,
  ScreenName,
  IdentityGroupType,
  Asset,
  TaskTypeMnemonic,
} from 'enums';

import {
  useIdentity,
  useTaskTemplateList,
  usePersonDetails,
} from 'hooks';

import {
  BodyMedium,
  HeadlineLarge,
  Scene,
  HBoxV,
  VContainer,
  TitleSmall,
  VFillContainerV,
  Feedback,
} from 'ui/common';

import {
  BodySmall,
} from 'ui/common/components/typography';

import {
  PrimaryButton,
} from 'ui/common/components/buttons';

import React from 'react';

import Grid from '@mui/material/Grid';

import {
  styled,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { personDetailsByIdQueryParams } from 'gql/person/queryParams';
import { getNonAssessmentTasks } from 'gql/taskTemplate/queryParams';
import { useWeeklyPlans } from 'hooks/useWeeklyPlans';
import { taskListWeeklyPlansQueryParams } from 'gql/task/queryParams';
import { getWeekRange } from 'common';

const StyledHeadline = styled(HeadlineLarge)`
  margin-top: 2rem;
`;

const StyledBody = styled(BodyMedium)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;
const StyledVContainer = styled(VContainer)`
  padding-left: 0 !important;
`;

const TaskBox = styled(HBoxV)`
  border-radius: 12px;
  padding: 12px;
  margin-top: 12px;
  border-width: 1px;
  border-style: solid;
`;

const TaskButton = styled(PrimaryButton)(
({ theme }) => `
  width: unset;
  background-color: ${theme.palette.primary.p40};
  `,
);

const TitleText = styled(TitleSmall)`
  margin: 0.25rem 0;
`;

export const TaskTemplate = () => {
  const navigate = useNavigate();
  const handleClick = (task) => {
    navigate(`/add-task/create?id=${task.id}`);
  }

  const { user } = useIdentity(TaskTemplate.context);
  const group = user?.group || IdentityGroupType.Intern;
  const weeklyRange = getWeekRange();

  const {
    loading: personDetailsLoading, 
    personDetails,
    error: personDetailsError
  } = usePersonDetails(personDetailsByIdQueryParams(user?.id), true);

  const {
    loading,
    error,
    personSpecificTaskTemplateList,
  } = useTaskTemplateList([group.toUpperCase()], personDetails?.[0]?.yearOfStudy, getNonAssessmentTasks([group.toUpperCase()]) );

  const {
    loading: weeklyLoading,
    weeklyPlanList,
  } = useWeeklyPlans(
    taskListWeeklyPlansQueryParams(user?.id, [weeklyRange.currentWeek[0], weeklyRange.nextWeek[1]]),
    group !== IdentityGroupType.Intern,
  );

  const hideWeeklyPlan = weeklyPlanList && weeklyPlanList.length > 0;
  
  return (

    <Scene
      headerVisible={true}
      title={ScreenName.TaskTemplate}
      footerVisible={true}
      loading={loading || personDetailsLoading || weeklyLoading}
    >

      <React.Fragment>

        <StyledVContainer>
          <StyledHeadline>
            ADD A TASK
          </StyledHeadline>

          <StyledBody>
            Browse the tasks available for addition to your schedule. Tap on any task to view further details or begin the task.
          </StyledBody>
        </StyledVContainer>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {!loading && !error && !personDetailsLoading && !personDetailsError && personSpecificTaskTemplateList && personSpecificTaskTemplateList.length > 0 &&
            personSpecificTaskTemplateList.filter(task => (!hideWeeklyPlan) || (hideWeeklyPlan && task.mnemonic !== TaskTypeMnemonic.InternPlanUpcomingWeek)).map(task => 
              <Grid key={task.id} item xs={12} sm={6} md={4}>
                <TaskBox backgroundColor={'primary.p99'} borderColor={'primary.contrastText'} >

                  <VFillContainerV>
                    <TitleText color="primary.p10">{task.title}</TitleText>
                      <BodySmall color="neutralVariant">{task.shortDescription}</BodySmall>
                  </VFillContainerV>

                    <TaskButton text={'Open'} onClick={() => handleClick(task)} />

                  </TaskBox>
              </Grid>
            )
          }

          {group === IdentityGroupType.Mentor &&
          
            <Grid key={'Assessment'} item xs={12} sm={6} md={4}>
              <TaskBox backgroundColor={'primary.p99'} borderColor={'primary.contrastText'} >

                <VFillContainerV>
                  <TitleText color="primary.p10">Student Evaluation</TitleText>
                    <BodySmall color="neutralVariant">Complete the performance evaluation to assess skills across designated competencies and motivate your ratings, then utilise feedback to pinpoint development opportunities.</BodySmall>
                </VFillContainerV>

                  <TaskButton text={'Open'} onClick={() => navigate('/add-task/create/assessment')} />

                </TaskBox>
            </Grid>
          }

        </Grid>

          {!loading && !personDetailsLoading && !personDetailsError && !error && group !== IdentityGroupType.Mentor &&
            ( personSpecificTaskTemplateList == null || personSpecificTaskTemplateList.length < 1) &&
            <BodyMedium>No task templates available</BodyMedium>
          }

          {error &&
            <Feedback
              imageUrl={Asset.Image.Other.BrokenPencil}
              headerText='Something Went Wrong!'
              bodyText="We encountered a hiccup while fetching your task templates. Not to worry, though – we're on it!"
              infoText="Please try loading your task templates again. If the issue persists, reach out to us for support"
              type="Error"
              buttonText='Try Again'
              buttonAction={() => window.location.reload()}
            />
          }
      </React.Fragment>
      
    </Scene>
  );
};

TaskTemplate.displayName = 'TaskTemplate';

TaskTemplate.context = {
  screen: ScreenName.TaskTemplate,
  journey: JourneyName.TaskTemplate,
  component: TaskTemplate.displayName,
};
