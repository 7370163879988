import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const LabelLarge = props =>

  <Typography
    color='secondary'
    {...props}
    fontSize='0.688rem'
    variant={TypographyVariant.Body2}>

    {props.children}
  </Typography>
;

LabelLarge.displayName = 'LabelLarge';
