import React from 'react';

import PropTypes from 'prop-types';

import {
  styled,
  LinearProgress,
  Container,
} from '@mui/material';

import {
  useTaskTemplateList,
} from 'hooks';

import {
  getNonAssessmentTasks,
} from 'gql/taskTemplate/queryParams';

import {
  IdentityGroupType,
} from 'enums';

import {
  Autochips,
} from 'ui/common';

const StyledContainer = styled(Container)(() => ({
  margin: '0 !important',
  padding: '0 !important',
  marginTop: '1rem !important',
  maxWidth: 'unset !important',
}));

const StyledProgress = styled(LinearProgress)(() => ({
  marginTop: '-1rem',
}));

export const InternTaskTypeFilter = props => {

  const {
    loading,
    error,
    taskTemplateList,
  } = useTaskTemplateList(
    [IdentityGroupType.Intern.toUpperCase()],
    null,
    getNonAssessmentTasks([IdentityGroupType.Intern.toUpperCase()])
  );

  const options = React.useMemo(() => {

    if (taskTemplateList == null) {
      return [];
    }

    return taskTemplateList.map(taskTemplate => {
      return {
        id: taskTemplate.id,
        label: taskTemplate.title,
      };
    });
  }, [
    taskTemplateList,
  ]);

  return (

    <React.Fragment>

      <StyledContainer>
        <Autochips
          {...props}
          disabled={loading || error}
          key={'intern-task-type-autochip'}
          chipMargin={'0 0 0 0'}
          label={`Student Tasks ${props.isRequired && '(Required)' || ''}`}
          options={options}
          onChange={(value) => props?.selectedOptions != value && props.onChange(value)}/>
      </StyledContainer>

      { loading &&
        <StyledProgress />
      }

    </React.Fragment>
  );
};

InternTaskTypeFilter.displayName = 'InternTaskTypeFilter';

InternTaskTypeFilter.propTypes = {
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  selectedOptions: PropTypes.any,
  onChange: PropTypes.func,
};
