import { IdentityGroupType } from "enums";

export const buildPersonUpsertVariables = (persons) => {

  const mappedPersons = persons.map(person => {

    const resultPerson = {};

      if (person.id) {
        resultPerson['id'] = person.id;
      }

      if (person.active) {
        resultPerson['active'] = person.active;
      }

      if (person.gender) {
        resultPerson['gender'] = person.gender;
      }

      if (person.telNo) {
        resultPerson['telNo'] = person.telNo;
      }

      if (person.givenName) {
        resultPerson['givenName'] = person.givenName;
      }

      if (person.surname) {
        resultPerson['surname'] = person.surname;
      }

      if (person.email) {
        resultPerson['email'] = person.email;
      }

      if (person.yearOfStudy) {
        resultPerson['yearOfStudy'] = person.yearOfStudy;
      }

      if (person.personTypeId) {
        resultPerson['personTypeId'] = person.personTypeId;
      }

      if (person.mentorPersonId) {
        resultPerson['mentorPersonId'] = person.mentorPersonId;
      }

      if (person.coachPersonId) {
        resultPerson['coachPersonId'] = person.coachPersonId;
      }

      if (person.teachingPhaseMnemonic) {
        resultPerson['teachingPhaseMnemonic'] = person.teachingPhaseMnemonic;
      }

      if (person.active !== null) {
        resultPerson['active'] = person.active;
      }

      return resultPerson;
  });

  return {
    variables: {
      params: {
        persons: mappedPersons,
      },
    }
  };
}


export const buildPersonSchoolUpsertVariables = (personSchools) => {

  const mappedPersonSchools = personSchools.map(ps => {

    const resultPersonSchool = {};

      if (ps.id) {
        resultPersonSchool['id'] = ps.id;
      }

      if (ps.active) {
        resultPersonSchool['active'] = ps.active;
      }

      if (ps.personId) {
        resultPersonSchool['personId'] = ps.personId;
      }
      
      if (ps.schoolId) {
        resultPersonSchool['schoolId'] = ps.schoolId;
      }

      return resultPersonSchool;
  });

  return {
    variables: {
      params: {
        personSchools: mappedPersonSchools,
      },
    }
  };
};

export const mapPersonFromQuery = (p) => {
  if (p == null) {
    return;
  }

  return {
    id: p?.id,
    email: p?.email,
    telNo: p?.telNo,
    givenName: p?.givenName,
    surname: p?.surname,
    yearOfStudy: p?.yearOfStudy,
    teachingPhaseMnemonic: p?.teachingPhaseMnemonic,
    mentorid: p?.mentorPerson?.id,
    mentorGivenName: p?.mentorPerson?.givenName,
    mentorSurname: p?.mentorPerson?.surname,
    coachGivenName: p?.coachPerson?.givenName,
    coachSurname: p?.coachPerson?.surname,
    coachid: p?.coachPerson?.id,
    schools: p?.schools?.map((school) => school?.title),
    schoolsObj: p?.schools,
    gender: p?.gender,
    active: p?.active
  };
}

export const getUserTypeLabel = (userType) => {
  switch(userType) {

    case IdentityGroupType.Admin:
      return 'Admin';
    case IdentityGroupType.Mentor:
      return 'On-site Mentor';
    case IdentityGroupType.Coach:
      return 'Online Mentor';
    default:
      return 'Student';
  } 
};
