import React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import {
  styled,
} from '@mui/material';

import {
  addDays,
  endOfDay,
  startOfDay,
} from 'date-fns';

import {
  AssessmentFullName,
  AssessmentMnemonic,
  Asset,
  IdentityGroupType,
  JourneyName,
  ScreenName,
  StatusMnemonic,
} from 'enums';

import {
  useIdentity,
  usePeopleList,
  useTaskTemplateList,
  useTaskUpsert,
} from 'hooks';

import {
  buildTaskUpsertVariables,
  validateAssessmentForm,
} from 'common';

import {
  Autochips,
  BodyMedium,
  DatePicker,
  Feedback,
  HeadlineSmall,
  LongText,
  PrimaryButton,
  Scene,
  ShortText,
  SingleSelect,
  TitleMedium,
} from 'ui/common';

import {
  getAllAssessmentTasks,
} from 'gql/taskTemplate/queryParams';

const StyledHeader = styled(HeadlineSmall)`
  margin: 1rem 0 .8rem 0;
`;

const StyledTitleMedium = styled(TitleMedium)`
  margin: 1rem 0 .8rem 0;
`;

const StyledBodyMedium = styled(BodyMedium)`
  margin: .2rem 0 1rem 0;
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: 1rem;
`;

export const CreateAssessmentTask = () => {

  const { user } = useIdentity(CreateAssessmentTask.context);
  const userType = user.group || IdentityGroupType.Mentor;
  const [filterInterns, setFilterInterns] = React.useState([]);
  const [indicators, setIndicators] = React.useState([]);
  const [yearOfStudy, setYearOfStudy] = React.useState([]);

  const navigate = useNavigate();

  const {
    loading: listLoading,
    error: listError,
    taskTemplateList,
  } = useTaskTemplateList([IdentityGroupType.Intern.toUpperCase()], null, getAllAssessmentTasks([IdentityGroupType.Intern.toUpperCase()]));

  const {
    loading,
    error,
    peopleList: internList,
    refetch,
  } = usePeopleList({
    userId: user.id,
    userType,
  });

  React.useEffect(() => {
    if (!loading && internList && internList.length > 0) {
      const internsOptions = internList.map(intern => ({
        id: intern.id,
        label: intern.fullName,
        yearOfStudy: intern.yearOfStudy,
      }));
      setFilterInterns(internsOptions);

      const indicatorsOptions = Object.keys(AssessmentMnemonic).map(mnemonic => ({
        id: AssessmentMnemonic[mnemonic],
        label: AssessmentFullName[mnemonic],
      }));
      setIndicators(indicatorsOptions);

      const uniqueYears = [...new Set(internList.map(intern => intern.yearOfStudy))].sort((a, b) => a - b);
      const yearOfStudyList = uniqueYears.map(year => ({
        id: year,
        label: year.toString(),
      }));
      setYearOfStudy(yearOfStudyList);
    }
  }, [
    internList,
    loading,
  ]);

  const [
    upsertTask, {
      loading: upsertLoading,
      data: upsertData,
      error: upsertError,
    },
  ] = useTaskUpsert();

  const [formFields, setFormFields] = React.useState();
  const [isFormValid, setIsFormValid] = React.useState(true);
  const [validation, setValidation] = React.useState({});

  React.useEffect(() => {

    if (loading) {
      return;
    }

    const startDate = startOfDay(new Date());
    const endDate = endOfDay(addDays(startDate, 6));

    const fields = {
      scheduledStartTimestamp: startDate,
      scheduledEndTimestamp: endDate,
      location: '',
      note: '',
      interns: [],
      indicators: [],
      yearOfStudy: [],
    };

    setFormFields(fields);
  }, [loading]);

  const handleFormFieldChange = (e, field) => {
    setFormFields(prevFields => {

      let value = e.target?.value;

      // Do not allow assessment start or end times to be changed.
      if (field === 'scheduledStartTimestamp' || field === 'scheduledEndTimestamp') {
        return prevFields;
      }

      if (field === 'interns' || field === 'indicators' || field === 'yearOfStudy') {
        value = e;
      }

      const newFields = {
        ...prevFields,
        [field]: value,
      };

      const errorMap = validateAssessmentForm(newFields);
      setValidation(errorMap);
      setIsFormValid(Object.keys(errorMap).length === 0);

      return newFields;
    });
  };

  const getAssessmentTemplate = (intern, indicator) => {
    return taskTemplateList.find((task) => task.yearOfStudy === intern.yearOfStudy && task.assessmentMnemonic === indicator.id);
  };

  const filterInternsByYear = (interns, years) => {
    return interns?.filter(intern => years.some(year => year.id === intern.yearOfStudy));
  };

  const handleOnCreate = () => {
    let interns = formFields.interns;
    if (formFields.typeSelector === 'Year Of Study') {
      interns = filterInternsByYear(internList, formFields.yearOfStudy);
      formFields.interns = interns;
    }
    const taskUpsertVariables = interns.map((intern) => {
      return formFields.indicators.map((indicator) => {
        const targetTemplate = getAssessmentTemplate(intern, indicator);
        return {
          title: targetTemplate.title,
          description: targetTemplate.description !== '' && targetTemplate.description || '',
          scheduledStartTimestamp: formFields.scheduledStartTimestamp,
          scheduledEndTimestamp: formFields.scheduledEndTimestamp,
          location: formFields.location !== '' && formFields.location || null,
          note: formFields.note !== '' && formFields.note || null,
          taskTemplateId: targetTemplate.id,
          ownerPersonId: intern.id,
          reporterPersonId: user.id,
          status: StatusMnemonic.Scheduled,
        };
      });
    }).flat();

    upsertTask(buildTaskUpsertVariables(taskUpsertVariables));
  };

  return (

    <Scene
      headerVisible={true}
      title={ScreenName.CreateTask}
      footerVisible={true}
      loading={loading || upsertLoading}
    >

      <React.Fragment>

        {!loading && formFields && !upsertData && taskTemplateList &&
          <React.Fragment>
            <StyledHeader>
              Create Evaluation Task
            </StyledHeader>

            <BodyMedium>
              Take the first step in organising your educational activities by crafting a new task.
              Detail the specifics to align with your objectives and schedule.
            </BodyMedium>

            <StyledTitleMedium>Task Details</StyledTitleMedium>

            <StyledBodyMedium>
              These fields are pre-populated based on the standard structure of the task you've
              chosen. Customise the details to fit your unique plan for accomplishing this task.
            </StyledBodyMedium>

            <DatePicker
              disabled={true}
              value={formFields.scheduledStartTimestamp}
              label={'Start Time'}
              format={'dd/MM/yyyy hh:mm aa'}
              slotProps={{
                textField: {
                  error: validation.scheduledStartTimestamp?.hasError,
                  helperText: validation.scheduledStartTimestamp?.errorText,
                },
              }}
            />

            <DatePicker
              disabled={true}
              value={formFields.scheduledEndTimestamp}
              label={'End Time'}
              format={'dd/MM/yyyy hh:mm aa'}
              slotProps={{
                textField: {
                  error: validation.scheduledEndTimestamp?.hasError,
                  helperText: validation.scheduledEndTimestamp?.errorText,
                },
              }}
            />

            <ShortText
              value={formFields.location}
              onChange={(e) => handleFormFieldChange(e, 'location')}
              label={'Task Location (optional)'}
              placeholder={'Task Location (optional)'}
            />

            <LongText
              value={formFields.note}
              onChange={(e) => handleFormFieldChange(e, 'note')}
              label={'Additional Notes (Optional)'}
            />

            <StyledTitleMedium> Student Selection </StyledTitleMedium>

            <StyledBodyMedium>
              Choose the student you wish to evaluate. Each selected student will be assigned the
              evaluation tasks based on the indicators you select next. Please select whether you'd
              like to select student by year of study or individually.
            </StyledBodyMedium>

            <SingleSelect
              key={'type-person-selector'}
              value={formFields?.typeSelector}
              onChange={(e) => {
                handleFormFieldChange(e, 'typeSelector');
              }}
              options={[
                'Year Of Study',
                'Individual',
              ]}
            />
            {formFields.typeSelector === 'Individual' && formFields.yearOfStudy.length == 0 &&
              <Autochips
                key={'person-autochip'}
                label={'Select Student'}
                options={filterInterns}
                onChange={(e) => formFields?.interns != e && handleFormFieldChange(e, 'interns')}
              />
            }
            {formFields.typeSelector === 'Year Of Study' && formFields.interns.length == 0 &&
              <Autochips
                key={'person-autochip'}
                label={'Select Year of Study'}
                options={yearOfStudy}
                onChange={(e) => formFields?.yearOfStudy != e && handleFormFieldChange(e, 'yearOfStudy')}
              />
            }

            <StyledTitleMedium> Evaluation Indicator Selection </StyledTitleMedium>

            <StyledBodyMedium>
              Select the evaluation indicators for assessment. These indicators will determine the
              specific evaluation tasks assigned to each chosen student. Once the students complete
              their tasks, you will be notified to review their self-assessments and provide your
              evaluations.
            </StyledBodyMedium>

            <Autochips
              key={'indicator-autochip'}
              label={'Select Indicator'}
              options={indicators}
              onChange={(e) => formFields?.indicator !== e && handleFormFieldChange(e, 'indicators')}
            />


            <StyledButton
              text={'Create'}
              onClick={handleOnCreate}
              disabled={!isFormValid}
            />
          </React.Fragment>
        }

        {((!loading && error) || (!listLoading && listError)) &&

          <Feedback
            imageUrl={Asset.Image.Other.BrokenPencil}
            headerText={'Something Went Wrong!'}
            bodyText={'We encountered a hiccup while fetching your task details. Not to worry, though – we\'re on it!'}
            infoText={'Please try loading your task again. If the issue persists, reach out to us for support'}
            type={'Error'}
            buttonText={'Try Again'}
            buttonAction={() => refetch()}/>
        }

        {!upsertLoading && upsertError &&
          <Feedback
            imageUrl={Asset.Image.Other.BrokenPencil}
            headerText={'Something Went Wrong!'}
            bodyText={'We encountered a hiccup while creating your task. Not to worry, though – we\'re on it!'}
            infoText={'Please try creating your task again. If the issue persists, reach out to us for support'}
            type={'Error'}
            buttonText={'Try Again'}
            buttonAction={() => handleOnCreate()}/>
        }

        {!upsertLoading && upsertData &&
          <Feedback
            imageUrl={Asset.Image.Other.PinnedCalender}
            headerText={'Task Created'}
            bodyText={'Great job creating your task! Your dedication shines bright.'}
            infoText={'Your task has been added to your task list.'}
            buttonText="Go Back"
            buttonAction={() => navigate('/home')}/>
        }
      </React.Fragment>
    </Scene>
  );
};

CreateAssessmentTask.displayName = 'Create Task';

CreateAssessmentTask.context = {
  screen: ScreenName.CreateTask,
  journey: JourneyName.CreateAssessmentTask,
  component: CreateAssessmentTask.displayName,
};
