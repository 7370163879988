export const HighContrastThutoTheme = {
    typography: {
      fontFamily: 'Inter',
      body1: {
        fontWeight: 600,
      },
      body2: {
        fontWeight: 800,
      },
      h1: {
        fontWeight: 800,
      },
      h2: {
        fontWeight: 800,
      },
      h3: {
        fontWeight: 800,
      },
      h4: {
        fontWeight: 900,
      },
      h5: {
        fontWeight: 800,
      },
      h6: {
        fontWeight: 800,
      },
      caption: {
        fontWeight: 600,
      },
      button: {
        fontWeight: 600,
      },
      overline: {
        fontWeight: 600,
      },
      subtitle1: {
        fontWeight: 800,
      },
      subtitle2: {
        fontWeight: 800,
      }
    },
    palette: {
      primary: {
        main: '#2D6F1F', // Darker green for higher contrast
        light: '#8BC86E', // Lighter green for contrast
        dark: '#0B1709', // Darker background for text to pop
        contrastText: '#FFFFFF', // White text on green background
  
        p10: '#0B1709', // Deep dark green for contrast
        p20: '#1A3B17', // Dark green for subtle contrast
        p30: '#2D6F1F', // Primary green
        p40: '#3CAA49', // Lighter green for hover/click effects
        p50: '#54B45F', // Light pastel green
        p60: '#6CBF76', // Soft light green
        p70: '#85C98D', // Light pastel green
        p80: '#9DD4A4', // Lighter mint
        p90: '#B5DFBA', // Very light mint
        p95: '#CEE9D1', // Faint mint
        p99: '#E6F4E8', // Almost white mint
        p100:'#F2F9F3', // Almost white
  
      },
      secondary: {
        main: '#3F4A4D', // Darker muted gray-blue for striking contrast
        light: '#9CA9AC', // Light gray for readability
        dark: '#101818', // Almost black for dark background
        contrastText: '#FFFFFF', // White text on gray-blue background
  
        s10: '#101818', // Darkest gray for text
        s20: '#232E30', // Dark gray-blue for contrast
        s30: '#3F4A4D', // Secondary gray-blue
        s40: '#5C6E72', // Lighter muted gray
        s50: '#708083', // Softer muted gray
        s60: '#849295', // Light gray for background hover
        s70: '#99A4A6', // Subtle gray
        s80: '#ADB6B8', // Very light gray
        s90: '#C1C8CA', // Almost white gray
        s95: '#D6DADB', // Very faint gray
        s99: '#EAECED', // Almost white gray
        s100: '#F4F5F5', // Very light gray
  
      },
      tertiary: {
        main: '#6C4C2D', // Dark brown for higher contrast with light backgrounds
        light: '#D1A47E', // Lighter tan for contrast
        dark: '#2A1C0E', // Darker brown for dramatic contrast
        contrastText: '#FFFFFF', // White text on brown background
  
        t10: '#2A1C0E', // Deep dark brown
        t20: '#5B3D1F', // Muted brown for strong contrast
        t30: '#6C4C2D', // Tertiary brown
        t40: '#A56C44', // Lighter brown for background
        t50: '#040303', // Very dark brown
        t60: '#D7A178', // Soft brown for hover states
        t70: '#E1C092', // Light brown
        t80: '#E7C89E', // Pale brown
        t90: '#F2D2A0', // Very light brown
        t95: '#F9E3C5', // Subtle pale brown
        t99: '#FCF0DA', // Almost white brown
  
      },
      error: {
        main: '#D32F2F', // Brighter red for more attention-grabbing error
        light: '#F8D7D7', // Light red for subtle contrast
        dark: '#9A1B1B', // Dark red for emphasis
        contrastText: '#FFFFFF', // White text on red background
  
        e10: '#9A1B1B', // Darkest red for emphasis
        e20: '#B92A2A', // Darker red
        e30: '#D32F2F', // Error red
        e40: '#E64A4A', // Stronger red for warning
        e50: '#FF5733', // Bright red
        e60: '#FF7F7F', // Soft red for subtle errors
        e70: '#FF9A9A', // Faint red
        e80: '#FFB5B5', // Very soft red
        e90: '#F8D7D7', // Very light red
        e95: '#FCE6E6', // Almost white red
        e99: '#FFFBFB', // Very light red
  
      },
      neutral: {
        main: '#3C3C3C', // Darker neutral colors for contrast
        light: '#BFBFBF', // Light neutral for balance
        dark: '#1A1A1A', // Almost black for deep contrast
        contrastText: '#FFFFFF', // White text on neutral background
  
        n10: '#1A1A1A', // Very dark gray
        n20: '#333333', // Dark gray for text
        n30: '#4D4D4D', // Regular neutral gray
        n40: '#666666', // Lighter neutral gray
        n50: '#808080', // Muted gray
        n60: '#999999', // Light neutral gray
        n70: '#B3B3B3', // Very light gray
        n80: '#CCCCCC', // Almost white neutral
        n90: '#E6E6E6', // Light neutral gray
        n95: '#F2F2F2', // Very light neutral gray
        n99: '#FFFFFF', // White
  
      },
      neutralVariant: {
        main: '#2E3536', // Darker neutral variant for stronger contrast
        light: '#A7B0B1', // Lighter neutral variant for readability
        dark: '#101818', // Dark background for readability
        contrastText: '#FFFFFF', // White text on dark neutral variant background
  
        nv10: '#101818', // Very dark neutral variant
        nv20: '#2A3536', // Darker variant of neutral
        nv30: '#3F4B4C', // Muted gray variant for background
        nv40: '#5D7072', // Light neutral variant for soft background
        nv50: '#748080', // Muted gray for soft UI elements
        nv60: '#8A9799', // Soft gray for faint backgrounds
        nv70: '#A0A9AA', // Lighter neutral variant
        nv80: '#B7BFC0', // Pale neutral variant
        nv90: '#CED7D8', // Almost white neutral variant
        nv95: '#E4E9EA', // Very light neutral variant
        nv99: '#FFFFFF', // White
  
      },
      standard: {
        black: '#000000', // Black for the highest contrast
        white: '#FFFFFF', // White for maximum contrast with dark backgrounds
      },
    },
  };
  