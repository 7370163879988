import React from 'react';

import { styled } from '@mui/material';

import { 
  useIdentity,
  usePersonDetails,
  useTheme,
} from 'hooks';

import {
  JourneyName,
  ScreenName,
  IdentityGroupType,
  ButtonVariant,
  RoutePath,
  themes
} from 'enums';

import { 
  PrimaryButton, 
  Scene, 
  HeadlineLarge,
  BodyMedium,
  VContainer,
  TitleMedium,
  SecondaryButton,
  LabelLarge,
  SpinnerButton,
  ToggleButton
} from 'ui/common';
import { useNavigate } from 'react-router-dom';
import { personDetailsByIdQueryParams } from 'gql/person/queryParams';
import { getUserTypeLabel } from 'common/person';

const MarginContainer = styled(VContainer)`
 margin-bottom: 1.8rem;
`;

const StyledHeadlineLarge = styled(HeadlineLarge)`
 margin-bottom: 0.5rem;
`;

const StyledTitleMedium = styled(TitleMedium)`
 margin-bottom: 0.5rem;
`;

const StyledButton = styled(PrimaryButton)`
@media (min-width: 600px) {
  max-width: 228px;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
@media (min-width: 600px) {
  max-width: 228px;
  }
`;

const StyledSpinnerButton = styled(SpinnerButton)`
 @media (min-width: 600px) {
   max-width: 228px;
   }
	 `;

export const Profile = () => {

  const navigate = useNavigate();

  const { user, logout, resetPassword } = useIdentity(Profile.context);
  const {selectedThemeName, toggleTheme} = useTheme();
  const [errorText, setErrorText] = React.useState('');
  const [resetLoading, setResetLoading] = React.useState(false);

  const {personDetails ,loading, error} = usePersonDetails(personDetailsByIdQueryParams(user?.id), true);
  const userType = user.group || IdentityGroupType.Intern; 

  const handleResetPassword = async () => {
    try {
      setResetLoading(true);
      await resetPassword();
      setErrorText('');
    } catch (error) {
      setErrorText(error.message);
    } finally {
      setResetLoading(false);
    }
  };

  const userData = React.useMemo(() => {
    const data = {
      "Full Name": `${personDetails[0]?.givenName} ${personDetails[0]?.surname}`,
      "Email Address": personDetails[0]?.email,
      "Contact Number": personDetails[0]?.telNo,
      "Year of Study": personDetails[0]?.yearOfStudy || 'N/A',
      "Teaching Phase": personDetails[0]?.teachingPhaseMnemonic || 'N/A',
      "School/Organisation Affiliation": personDetails[0]?.schools?.join(', '),
      "Role": getUserTypeLabel(userType),
    };
    if (userType !== IdentityGroupType.Mentor && userType !== IdentityGroupType.Coach && userType !== IdentityGroupType.Admin) {
      data["Phase"] = personDetails[0]?.teachingPhaseMnemonic;
      data["On-site Mentor Name"] = `${personDetails[0]?.mentorGivenName} ${personDetails[0]?.mentorSurname}`;
      data["Online Mentor Name"] = `${personDetails[0]?.coachGivenName} ${personDetails[0]?.coachSurname}`;
    }
    return data;
  }, [personDetails, userType]);

  return (

    <Scene
      headerVisible={true}
      footerVisible={true}
      title={ScreenName.Profile}
      loading={loading}
    >
      <React.Fragment>

        <MarginContainer>
          <StyledHeadlineLarge>YOUR PROFILE</StyledHeadlineLarge>
          <BodyMedium>Manage your personal information, settings, and preferences here to customise your experience.</BodyMedium>
        </MarginContainer>

        <MarginContainer>
          <StyledTitleMedium>PERSONAL INFORMATION</StyledTitleMedium>
          <BodyMedium>Keep your personal details up to date to ensure seamless communication and service.</BodyMedium>
        </MarginContainer>

        {personDetails && !loading && !error && Object.entries(userData)?.map(([key, value]) => (
          <React.Fragment key={key}>
            <MarginContainer>
              <StyledTitleMedium>{key}</StyledTitleMedium>
              <BodyMedium>{value}</BodyMedium>
            </MarginContainer>
          </React.Fragment>
        ))}

        <MarginContainer>
          <StyledButton text='Edit Details' onClick={()=> navigate(RoutePath.EditProfile)}/>
        </MarginContainer>

        <MarginContainer>
          <StyledTitleMedium>CHANGE YOUR THEME</StyledTitleMedium>
          <BodyMedium>Click on the button below to change theme across the application.</BodyMedium>
        </MarginContainer>

        <MarginContainer>
          <ToggleButton
            value={selectedThemeName}
            options={[
              {
                display: "Light",
                value: themes.light
              },
              {
                display: "High Contrast",
                value: themes.highContrast
              },
            ]}
            onChange={(e) => toggleTheme(e.target.value)}/>
        </MarginContainer>

        <MarginContainer>
          <StyledTitleMedium>UPDATE YOUR PASSWORD</StyledTitleMedium>
          <BodyMedium>Click on the button below to reset your password. Please note you will be redirected to a new window.</BodyMedium>
        </MarginContainer>

        <MarginContainer>
          {errorText && <LabelLarge color='error'>{errorText}</LabelLarge>}

          <StyledSpinnerButton 
            variant={ButtonVariant.Contained}
            loading={resetLoading}
            text='Reset Password'
            onClick={handleResetPassword}
          />
        </MarginContainer>
        
        <MarginContainer>
          <StyledTitleMedium>LOG OUT</StyledTitleMedium>
          <BodyMedium>For your security, remember to log out especially when using shared devices. Ready to sign off? </BodyMedium> <br/>
          <StyledSecondaryButton text='Log Out' onClick={logout} />
        </MarginContainer>



      </React.Fragment>
    </Scene>
    
  );
};
  
  
Profile.displayName = 'Profile';

Profile.context = {
  screen: ScreenName.Profile,
  journey: JourneyName.Profile,
  component: Profile.displayName,
};
  