import { useMutation } from '@apollo/client';

import { getWeekNormalised, mapTaskFromQuery } from 'common';
import { add, getYear } from 'date-fns';
import { DefaultSchedule } from 'enums';

import { ScheduleGInsert2Weeks } from 'gql/schedule/mutations';
import React from 'react';

export const useUpcomingSchedules = () => {

  const [scheduleGInsert2Weeks, { data, loading, error}] = useMutation(ScheduleGInsert2Weeks);
  const [currentSchedule, setCurrentSchedule] = React.useState(DefaultSchedule);
  const [nextWeekSchedule, setNextWeekSchedule] = React.useState(DefaultSchedule);

  const setCurrentScheduleCallback = React.useCallback((schedule) => {
    setCurrentSchedule({
      id: schedule.id,
      week: schedule.week,
      year: schedule.year,
      weeklyPlanTask: schedule.weeklyPlanTask,
      tasks: schedule.tasks.map((task) => mapTaskFromQuery(task)),
    });
  }, [setCurrentSchedule]);

  const setNextWeekScheduleCallback = React.useCallback((schedule) => {
    setNextWeekSchedule({
      id: schedule.id,
      week: schedule.week,
      year: schedule.year,
      weeklyPlanTask: schedule.weeklyPlanTask,
      tasks: schedule.tasks.map((task) => mapTaskFromQuery(task)),
    });
  }, [setNextWeekSchedule]);

  React.useEffect(() => {
    if (!loading && data && currentSchedule.id == null && nextWeekSchedule.id == null) {

      if (data.currentInsert?.body?.schedules && data.currentInsert?.body?.schedules[0]) {

        const schedule = data.currentInsert?.body?.schedules[0];

        setCurrentScheduleCallback(schedule);
      }

      if (data.nextWeekInsert?.body?.schedules && data.nextWeekInsert?.body?.schedules[0]) {

        const schedule = data.nextWeekInsert?.body?.schedules[0];

        setNextWeekScheduleCallback(schedule);
      }
    }
  }, [loading, data, currentSchedule, nextWeekSchedule, setCurrentScheduleCallback, setNextWeekScheduleCallback]);

  const getUpcomingSchedules = () => {
    
    if (loading) {
      return;
    }

    const now = new Date();
    const nextWeek = add(now, {weeks: 1});

    scheduleGInsert2Weeks({
      variables: {
        currentParams: {
          week: getWeekNormalised(now),
          year: getYear(now),
        },
        nextWeekParams: {
          week: getWeekNormalised(nextWeek),
          year: getYear(nextWeek),
        },
    }});
  }

  return [
    getUpcomingSchedules,
    {
      loading,
      error,
      data,
      currentSchedule,
      nextWeekSchedule,
    }
  ];
};
