import {
  Typography,
} from '@mui/material';

import {
  TypographyVariant,
} from 'enums';

export const BodySmall = props =>

  <Typography
    color='secondary'
    {...props}
    fontSize='0.812rem'
    variant={TypographyVariant.Body1}>

    {props.children}
  </Typography>
;

BodySmall.displayName = 'BodySmall';
