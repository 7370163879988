import React from 'react';

import PropTypes from 'prop-types';

import {
  previousMonday
} from 'date-fns';

import {
  DatePicker,
} from './Inputs/DatePicker';

import {
  LongText,
} from './Inputs/LongText';

import {
  ShortText,
} from './Inputs/ShortText';

export const TaskForm = ({ formFields, handleFormFieldChange, validation, isLinked }) => {
  
  return (
    <React.Fragment>
      
      <ShortText
        label={'Task Name'}
        value={formFields.title}
        error={validation.title?.hasError}
        helperText={validation.title?.errorText}
        onChange={(e) => handleFormFieldChange(e, 'title')}
      />

      <LongText
        label={'Task Description'}
        value={formFields.description}
        onChange={(e) => handleFormFieldChange(e, 'description')}
      />

      <DatePicker
        value={formFields.scheduledStartTimestamp}
        onChange={(e) => handleFormFieldChange(e, 'scheduledStartTimestamp')}
        label={'Start Time'}
        format='dd/MM/yyyy hh:mm aa'
        disabled={isLinked}
        minDate={previousMonday(new Date())}
        slotProps={{
          textField: {
            error: validation.scheduledStartTimestamp?.hasError,
            helperText: validation.scheduledStartTimestamp?.errorText,
          },
        }}
      />
      <DatePicker
        value={formFields.scheduledEndTimestamp}
        onChange={(e) => handleFormFieldChange(e, 'scheduledEndTimestamp')}
        label={'End Time'}
        format='dd/MM/yyyy hh:mm aa'
        disabled={isLinked}
        slotProps={{
          textField: {
            error: validation.scheduledEndTimestamp?.hasError,
            helperText: validation.scheduledEndTimestamp?.errorText,
          },
        }}
      />

      <ShortText
        value={formFields.location}
        onChange={(e) => handleFormFieldChange(e, 'location')}
        label={'Place'}
        placeholder={'e.g. Classroom 2B'}
        disabled={isLinked}
      />

      <LongText
        value={formFields.note}
        onChange={(e) => handleFormFieldChange(e, 'note')}
        label={'Notes'}
        disabled={isLinked}
      />

    </React.Fragment>
    );
};

TaskForm.displayName = 'TaskForm';

TaskForm.propTypes = {
  formFields: PropTypes.object.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  validation: PropTypes.object,
  isLinked: PropTypes.bool,
};

TaskForm.defaultProps = {
  validation: {},
  isLinked: false,
};