import React from 'react';

import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  styled,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  EvalRatingIdentityGroup,
  UnicodeCharacters,
} from 'enums';

import {
  isFunction,
} from 'common';

import {
  InfoText,
} from 'ui/common/components/form/InfoText';

import {
  BodyMedium,
  TitleMedium,
} from 'ui/common/components/typography';

import {
  Rating,
} from 'ui/common/components/form/Inputs/Rating';

import {
  LongText,
} from 'ui/common/components/form/Inputs/LongText';

const StyledLongText = styled(LongText)`
  margin: 1rem 0 1.8rem 0;
`;

const CustomAccordion = styled(Accordion)`
  box-shadow: none;
  margin-bottom: 1rem;
  padding: 0 1rem;
  border: 1px solid ${({theme}) => theme.palette.primary.p95};
  background-color: ${({theme}) => theme.palette.primary.p99};

  &.Mui-expanded:last-of-type {
    margin-bottom: 1rem;
  }
`;

const CustomAccordionSummary = styled(AccordionSummary)`
  background-color: transparent;
  box-shadow: none;
  padding: 0;
`;

const CustomAccordionDetails = styled(AccordionDetails)`
  background-color: transparent;
  padding: 0;
`;

const StyledRating = styled(Rating)`
  padding: 1rem 0 1rem 0;
`;

const FieldName = {
  Rating: 'rating',
  Response: 'response',
};

export const EvalRating = props => {

  const {
    title,
    description,
    id,
    onChange,
    value,
    min,
    max,
    inputType,
    evalCritTitle,
    evalCritItems,
    internResponse,
    internRating,
    questionId,
    questionComments,
    upsertCommentLoading,
    handleComment,
    userId,
  } = props;

  const getDefaultState = (value) => {
    const defaultState = {
      rating: 1,
      response: undefined,
    };

    if (value != null && value !== '') {
      if (typeof value === 'object') {
        return value;
      }

      try {
        const parsedValue = JSON.parse(value);
        Object.keys(parsedValue).forEach((v) => {
          defaultState[v] = parsedValue[v];
        });
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }

    return defaultState;
  };

  const [state, setState] = React.useState(getDefaultState(value));

  const handleChange = ({
    name,
    value,
  }) => {
    setState((prevState) => {

      const newState = {
        ...prevState,
        [name]: value,
      };

      if (isFunction(onChange)) {
        onChange(JSON.stringify(newState));
      }

      return newState;
    });
  };

  return (
    <React.Fragment>

      <InfoText
        title={title}
        description={description}
        questionId={questionId}
        questionComments={questionComments}
        upsertCommentLoading={upsertCommentLoading}
        handleComment={handleComment}
        userId={userId}
      />

      <Grid
        container
        direction="column">

        {evalCritTitle &&
          <Grid
            item={true}>

            <CustomAccordion>

              <CustomAccordionSummary
                expandIcon={<ExpandMoreIcon/>}>

                <InfoOutlinedIcon
                  color={'secondary'}/>

                <TitleMedium>
                  {UnicodeCharacters.NonBreakingSpace}Evaluation Criteria
                </TitleMedium>
              </CustomAccordionSummary>

              <CustomAccordionDetails>
                <TitleMedium>
                  {evalCritTitle}
                </TitleMedium>

                <List
                  sx={{
                    listStyleType: 'disc',
                    pl: 2,
                  }}>
                  {evalCritItems?.map((item, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        display: 'list-item',
                        padding: '0 0 0 0.5rem',
                      }}>
                      <BodyMedium>{item}</BodyMedium>
                    </ListItem>
                  ))}
                </List>
              </CustomAccordionDetails>
            </CustomAccordion>
          </Grid>
        }

        {inputType === EvalRatingIdentityGroup.Intern &&
          <React.Fragment>
            <StyledRating
              name={FieldName.Rating}
              key={`${id}-rating`}
              value={state?.rating || 0}
              onChange={rating => handleChange({
                name: FieldName.Rating,
                value: rating,
              })}
              title={'Your Rating'}
              max={max}
              min={min}
            />

            <StyledLongText
              name={FieldName.Response}
              id={`${id}-response`}
              // TODO: Fix bachend template for placeholder
              label={'Response'}
              value={state?.response || ''}
              onChange={event => handleChange({
                name: FieldName.Response,
                value: event.target.value,
              })}
            />
          </React.Fragment>
        }

        {inputType === EvalRatingIdentityGroup.Mentor &&
          // Still need to get data for the linked task, can't seem to find on response.
          <React.Fragment>
            <StyledRating
              key={`${id}-intern-rating`}
              value={internRating}
              title={'Intern Rating'}
              disabled={true}/>

            <StyledLongText
              id={`${id}-intern-response`}
              // TODO: Fix bachend template for placeholder
              label={'Response'}
              value={internResponse}
              disabled={true}/>

            <StyledRating
              name={FieldName.Rating}
              key={`${id}mentor-rating`}
              value={state?.rating || 0}
              onChange={rating => handleChange({
                name: FieldName.Rating,
                value: rating,
              })}
              title={'Your Rating'}
              max={max}
              min={min}/>

            <StyledLongText
              name={FieldName.Response}
              id={`${id}-mentor-response`}
              // TODO: Fix bachend template for placeholder
              label={'Response'}
              value={state?.response || ''}
              onChange={event => handleChange({
                name: FieldName.Response,
                value: event.target.value,
              })}/>
          </React.Fragment>
        }
      </Grid>
    </React.Fragment>
  );
};

EvalRating.displayName = 'EvalRating';

EvalRating.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  groupName: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  inputType: PropTypes.string,
  evalCritTitle: PropTypes.string,
  evalCritItems: PropTypes.array,
  internResponse: PropTypes.any,
  internRating: PropTypes.any,
  id: PropTypes.string,
  questionId: PropTypes.any,
  questionComments: PropTypes.any,
  upsertCommentLoading: PropTypes.bool,
  handleComment: PropTypes.any,
  userId: PropTypes.string,
};

EvalRating.defaultProps = {
  title: '',
  description: '',
  groupName: '',
  helperText: '',
  error: false,
  min: 1,
  max: 5,
  name: '',
  inputType: '',
  evalCritTitle: '',
  evalCritItems: [],
  userId: '',
};
