import
  PropTypes
from 'prop-types';

import {
  RangeSelector
} from 'ui/common/components/form/RangeSelector';

import React from 'react';

import { getYear, getMonth, add } from 'date-fns';
import { getWeekNormalised, getFirstMondayNormalised } from 'common';

function getCurrentTimePeriod (today, timespan) {

  if(timespan === 'Month' ){
    return getMonth(add(today, {months: 1}));
  }
  
  if(timespan === 'Week' ){
    return getWeekNormalised(today)
  }

  return null;
}

const calculateWeeksOfYear = (year) => {
  const startDateWeekRange = [];
  const endDateWeekRange = [];
  const currentDate = getFirstMondayNormalised();

  // Iterate through the weeks of the year until the current date
  let weekNumber = 1;
  while (currentDate.getFullYear() <= year) {
    // Create the week label for Monday
    const currentMondayMonth = currentDate.toLocaleString('default', { month: 'short' });
    const currentMondayWeekDay = currentDate.toLocaleString('default', { weekday: 'short' });
    const mondayLabel = `Week ${weekNumber} - ${currentMondayWeekDay}, ${currentMondayMonth} ${currentDate.getDate()}`;
    startDateWeekRange.push({title: mondayLabel, num: weekNumber});

    // Calculate the Sunday of the same week
    const sundayDate = new Date(currentDate);
    sundayDate.setDate(currentDate.getDate() + 6);
    const currentSundayMonth = sundayDate.toLocaleString('default', { month: 'short' });
    const currentSundayWeekDay = sundayDate.toLocaleString('default', { weekday: 'short' });

    const sundayLabel = `Week ${weekNumber} - ${currentSundayWeekDay}, ${currentSundayMonth} ${sundayDate.getDate()}`;
    endDateWeekRange.push({title:sundayLabel, num: weekNumber});

    // Move to the next Monday
    currentDate.setDate(currentDate.getDate() + 7);
    weekNumber++;
  }

  return {
    startRange: startDateWeekRange,
    endRange: endDateWeekRange,
  }

}

function calculateMonthsOfYear(year) {
  const startDateMonthRange = [];
  const endDateMonthRange = [];

  for (let month = 0; month < 12; month++) {
    const firstMonday = getFirstMonday(year, month);
    const lastMonday = getLastMonday(year, month);
    const sundayOfLastWeek = new Date(lastMonday.setDate(lastMonday.getDate() + 6));
    
    startDateMonthRange.push({ num: month + 1, title: formatMonthDateLabel(year, month, firstMonday) });
    endDateMonthRange.push({ num: month + 1, title: formatMonthDateLabel(year, month, sundayOfLastWeek) });
  }

  return { 
    startRange: startDateMonthRange, 
    endRange: endDateMonthRange,
  };
}

const PeriodTypes = {
  week: {
    title: 'Week',
    startDateLabel: 'Select Date for the Starting Week',
    endDateLabel: 'Select Date for the Ending Week',
    calcRange: calculateWeeksOfYear 
  },
  month: {
    title: 'Month',
    startDateLabel: 'Select Date for the Starting Month',
    endDateLabel: 'Select Date for the Ending Month',
    calcRange: calculateMonthsOfYear,
  },
};

const defaultRanges = {
  start: {
    default: null,
    options: null,
    label: null
  },
  end: {
    default: null,
    options: null,
    label: null
  },
};

function getFirstMonday(year, month) {
  const date = new Date(year, month, 1);
  const day = date.getDay();
  const diff = (day <= 1) ? 1 - day : 8 - day;
  date.setDate(date.getDate() + diff);
  return date;
}

function getLastMonday(year, month) {
  const date = new Date(year, month + 1, 0);
  let day = date.getDay();
  day = (day === 0) ? 7 : day;
  const diff = (day == 1) ?  0 : day - 1 ;
  date.setDate(date.getDate() - diff);
  return date;
}

function formatMonthDateLabel(year, month , date) {
  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  const monthOptions = { month: 'long' };
  
  const monthName = (new Date(year, month, 1)).toLocaleDateString('en-US', monthOptions);
  const dayLabel = date.toLocaleDateString('en-US', options);

  return `${monthName} - ${dayLabel}`;
}

export const DateWeekMonthRangeSelector = props => { 
  const {
    onChange,
    periodType,
    selectedYear,
  } = props;

  const [state, setState] = React.useState({selectedStart: null, selectedEnd: null});
  const [ ranges, setRanges ] = React.useState(defaultRanges);

  React.useEffect(() => {

    if (!periodType || !selectedYear) {
      setRanges(defaultRanges);
      return;
    }
    
    const periodTypeObj = PeriodTypes[periodType.toLowerCase()];

    const rangeOptions = periodTypeObj?.calcRange(selectedYear);
    
    const today = new Date();
    const isCurrentYear = selectedYear === getYear(today);
    const currentPeriod = isCurrentYear ? getCurrentTimePeriod(today, periodType) : -1;

    const getTitle = (range, period) => range?.find(f => f.num === period)?.title ?? range?.[0]?.title;

    const defaultStart = getTitle(rangeOptions?.startRange, currentPeriod);
    const defaultEnd = getTitle(rangeOptions?.endRange, currentPeriod);

    setRanges((prevRanges) => {
      const newRanges = {
        start: {
          default: defaultStart,
          optionsKVP: rangeOptions?.startRange,
          options: rangeOptions?.startRange?.map(m => m.title),
          label: periodTypeObj?.startDateLabel
        },
        end: {
          default: defaultEnd,
          optionsKVP: rangeOptions?.endRange,
          options: rangeOptions?.endRange?.map(m => m.title),
          label: periodTypeObj?.endDateLabel
        },
      };

      if(prevRanges === newRanges) {
        return;
      }

      return newRanges;
    });

  }, [periodType, selectedYear]);



  const handleChange = (value) => {
    setState((prevState) => {

      const newState = {
        selectedStart: ranges.start.optionsKVP?.find(f => f.title === value?.selectedStart)?.num,
        selectedEnd: ranges.end.optionsKVP?.find(f => f.title === value?.selectedEnd)?.num,
      }

      if (prevState === newState) {
        return prevState;
      }

      onChange && onChange(newState);

      return newState;
    }); 
  };
  
  return (
    <React.Fragment>

      {
        ranges.start.options && ranges.end.options && state &&
        <RangeSelector
          onChange={handleChange}
          ranges={ranges}
        />
      }

    </React.Fragment>
  );
};

DateWeekMonthRangeSelector.displayName = 'DateWeekMonthRangeSelector';

DateWeekMonthRangeSelector.propTypes = {
  periodType: PropTypes.string,
  selectedYear: PropTypes.number,
  onChange: PropTypes.func,
};

DateWeekMonthRangeSelector.defaultProps = {
  periodType: 'week'
};
